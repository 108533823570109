import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../base/base.component';
import { StateInterface, Locale } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, RegistrationService, RecipesService, ManagementRecipesService, LayoutService } from '../../../../services';
import { _ } from '../../../../tools';

@Component({
	selector: 'product-list-inline-edit-item',
	template: require('./product-list-inline-edit-item.component.html')
})

export class ProductListInlineEditItemComponent extends BaseComponent implements OnInit {

	/**
	 * @type {any} - The editable object
	 */
	@Input() item: any = {
		name: {}
	};

	/**
	* @type {string} - subTitle of the block
	*/
	@Input() subTitle: string;

	/**
	* @type {boolean} - is the edit block open
	*/
	@Input() isEditVisible: boolean = false;
	@Input() isDeleteAllowed: boolean = false;

	@Input() isStandalone: boolean = false;
	@Input() itemType: string;

	@Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSave: EventEmitter<any> = new EventEmitter<any>();
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * The recipe form.
	 * @type {FormGroup}
	 */
	form: FormGroup;

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;

	/**
	 * Constructor.
	 * @return {void}
	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private store: Store<StateInterface>,
		private translate: TranslateService,
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale')).subscribe((locale: Locale) => {
			this.locale = _.cloneDeep(locale);
		}));
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			nameNl: new FormControl(this.item.name.nl, Validators.required),
			nameEn: new FormControl(this.item.name.en, Validators.required),
		});
	}

	/**
	 * Return wether or not should focus on a specific field.
	 * @param {string} field
	 * @return {boolean}
	 */
	getShouldFocus(field: string): boolean {
		const { isValidated, form } = this;
		const fields: any =  Object.keys(form.controls);

		let isOtherFieldsValid = true;

		// Checks if there are any invalid fields before this one
		fields.slice(0, fields.findIndex(control => control === field))
		.map((control) => {
			if (!form.get(control).valid) {
				isOtherFieldsValid = false;
			}
		});
		return (isValidated && isOtherFieldsValid && !form.get(field).valid);
	}

	clearInput(): void {
		if (this.isStandalone) {
			this.form.setValue({
				nameNl: '',
				nameEn: ''
			});
		} else if (this.item) {
			this.form.setValue({
				nameNl: this.item.name.nl,
				nameEn: this.item.name.en
			});
		}
	}

	cancel(event?: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.clearInput();
		this.onCancel.emit();
	}

	/**
	 * Upon submitting the form.
	 * @return {void}
	 */
	onSubmit(event?: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.isSubmitted = true;
		if (this.form.valid) {
			this.isValidated = true;

			this.onSave.emit(this.form.value);
			this.cancel();
		}
	}

	deleteRecipe(event?: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.translate.get('RESERVATION.RESERVATION.remove-confirmation-text').subscribe((translation: string) => {
			let userSelectedOption: boolean = confirm(translation);
			if (userSelectedOption) {
				this.onDelete.emit();
			}
		});
	}
}
