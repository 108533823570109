import { ActionReducer, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { account } from './auth/auth.reducer';
import { locale } from './locale/locale.reducer';
import { customnotifications } from './customnotifications/customnotifications.reducer';
import { applicationsettings } from './applicationsettings/applicationsettings.reducer';
import { locations } from './locations/locations.reducer';
import { cabinets } from './cabinets/cabinets.reducer';
import { cabinetPositions } from './cabinet-positions/cabinet-positions.reducer';
import { categories } from './categories/categories.reducer';
import { recipes } from './recipes/recipes.reducer';
import { managementRecipes } from './management-recipes/management-recipes.reducer';
import { filter } from './filter/filter.reducer';
import { registration } from './registration/registration.reducer';
import { saml } from './saml/saml.reducer';
import { feedback } from './feedback/feedback.reducer';
import { layout } from './layout/layout.reducer';

import { StateInterface } from './state.model';
import { _ } from '../tools';

export function logger(reducer: ActionReducer<StateInterface>): ActionReducer<StateInterface> {
	return (state: StateInterface, action: any): any => {
		const result = reducer(state, action);
		// Logs state changes to console if environment is server
		// if (process.env.ENV === 'server') {
		// 	console.info('%c\tPREV state: ', 'font-weight: bold; color: rgb(153, 153, 153)', state);
		// 	console.info(`%c${action.type}`, 'color: rgb(15, 106, 180); font-size: 13px; line-height: 30px; font-weight: 600;', action.payload);
		// 	console.info('%c\tNEXT state: ', 'font-weight: bold; color: rgb(84, 127, 0)', result);
		// }

		// Stores snapshot state to sessionStorage
		sessionStorage.setItem('stateSnapshot', JSON.stringify(result));

		return result;
	};
}

export const metaReducers: MetaReducer<StateInterface>[] = [logger, storeFreeze];

export const rootReducers = {
	account,
	locale,
	customnotifications,
	applicationsettings,
	locations,
	cabinets,
	cabinetPositions,
	categories,
	recipes,
	managementRecipes,
	filter,
	registration,
	saml,
	feedback,
	layout
};