import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { BaseComponent } from '../../base/base.component';

import { StateInterface } from '../../../store/state.model';
import { _, getState } from '../../../tools';

@Component({
	selector: 'navigation',
	template: require('./navigation.component.html')
})

export class NavigationComponent extends BaseComponent implements OnInit {

	/**
	 * Datastructure for the side menu
	 * @type {any[]}
	 */
	public menuSections: any[] = [
		{
			basePath: '/management/recipe',
			path: '/management/recipes',
			titleKey: 'MANAGEMENT.NAVIGATION.recipes-label',
			roles: ['superusers', 'administrators'],
		},
		{
			basePath: '/management/categor',
			path: '/management/categories',
			titleKey: 'MANAGEMENT.NAVIGATION.categories-label',
			roles: ['superusers', 'administrators'],
		},
		{
			basePath: '/management/user',
			path: '/management/users',
			titleKey: 'MANAGEMENT.NAVIGATION.users-label',
			roles: ['superusers', 'administrators'],
		},
		{
			basePath: '/management/report',
			path: '/management/reports',
			titleKey: 'MANAGEMENT.NAVIGATION.reports-label',
			roles: ['superusers', 'administrators'],
		},
		{
			basePath: '/management/cabinet',
			path: '/management/cabinets',
			titleKey: 'MANAGEMENT.NAVIGATION.cabinets-label',
			roles: ['superusers'],
		},
		{
			basePath: '/management/customnotifications',
			path: '/management/customnotifications',
			titleKey: 'MANAGEMENT.NAVIGATION.customnotification-label',
			roles: ['superusers'],
		},
		{
			basePath: '/management/applicationsettings',
			path: '/management/applicationsettings',
			titleKey: 'MANAGEMENT.NAVIGATION.applicationsettings-label',
			roles: ['superusers'],
		}
	];

	/**
	 * The account state.
	 * @type {any}
	 */
	account: any;

	/**
	 * Class constructor.
	 * @param {Router} router
	 * @return {void}
	 */
	constructor(
		private router: Router,
		private store: Store<StateInterface>
	) {
		super();
	}

	ngOnInit(): void {

		// Subscribes to the feedback state
		this.addSubscription(this.store.select('account')
			.subscribe((account: any) => {
				this.account = _.cloneDeep(account);
			})
		);

	}

	/**
	 * Check if a route is active.
	 * @param {string} route
	 * @return {boolean}
	 */
	isActive(route: string): boolean {
		const { url } = this.router.routerState.snapshot;
		return route === url || url.includes(route);
	}

	/**
	 * Check if a route is active.
	 * @param {string} route
	 * @return {boolean}
	 */
	hasRights(route: string): boolean {
		const { bearer, isLoggedIn, userGroups } = this.account;
		let hasrights = false;

		if (bearer && isLoggedIn) {
			for (let i = 0; i < this.menuSections.length; i++) {
				if (this.menuSections[i].basePath === route) {
					for (let j = 0; j < this.menuSections[i].roles.length; j++) {
						if (userGroups.indexOf(this.menuSections[i].roles[j]) > -1) {
							hasrights = true;
							break;
						}
					}
					break;
				}
			}
		}

		return hasrights;
	}
}
