import { Action } from '@ngrx/store';

export const ActionTypes = {
	ADD_NOTIFICATION: '[Feedback] ADD_NOTIFICATION',
	DISMISS_NOTIFICATION: '[Feedback] DISMISS_NOTIFICATION',
	SET_IS_LOADING: '[Feedback] SET_IS_LOADING',
	RESET_STATE: '[Feedback] RESET_STATE'
};

export class AddNotificationAction implements Action {
	readonly type = ActionTypes.ADD_NOTIFICATION;
	constructor(public payload?: any) {}
}

export class DismissNotificationAction implements Action {
	readonly type = ActionTypes.DISMISS_NOTIFICATION;
	constructor(public payload?: any) {}
}
export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| AddNotificationAction
	| DismissNotificationAction
	| SetIsLoadingAction;