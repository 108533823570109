import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { Actions, ActionTypes } from '../../store/cabinets/cabinets.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { _, tassign, getState } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Cabinets service.
 */
export class CabinetsService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve cabinets data from server and dispatch action
	 * to update the store accordingly.
	 * @return {Observable}
	 */
	hydrateCabinets(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http
			.get(`${this.apiEndpoint}cabinet`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Retrieve cabinet data.
	 * @return {Observable}
	 */
	hydrateCabinet(id: string): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http
			.get(`${this.apiEndpoint}cabinet/${id}`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Patches cabinet data.
	 * @return {Observable}
	 */
	patchCabinetById(id: string, payload): Observable<Response> {
		return this.http
			.patch(`${this.apiEndpoint}cabinet/${id}`, payload, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to load cabinets.
	 * @param {object} result
	 * @return {Actions}
	 */
	loadCabinets(result: any): Actions {
		const { cabinets } = getState(this.store);

		return {
			type: ActionTypes.LOAD_CABINETS,
			payload: tassign(cabinets, {
				items: ItemsHelper.mapCabinetItems(result),
				isLoading: false
			})
		};
	}

	/**
	 * Retrieve all the connected administrators of a cabinet.
	 * @return {Observable}
	 */
	getConnectedCabinetUsers(id: string): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http
			.get(`${this.apiEndpoint}cabinet/${id}/cabinetusers`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Retrieve all the available administrators of a cabinet.
	 * @return {Observable}
	 */
	getAvailableCabinetUsers(id: string): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http
			.get(`${this.apiEndpoint}cabinet/${id}/cabinetusers/available`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Patches cabinet data.
	 * @return {Observable}
	 */
	postConnectedCabinetUser(id: string, CabinetUserID: string): Observable<Response> {
		return this.http
			.post(`${this.apiEndpoint}cabinet/${id}/cabinetusers/${CabinetUserID}`, null, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Patches cabinet data.
	 * @return {Observable}
	 */
	deleteConnectedCabinetUser(id: string, CabinetUserID: string): Observable<Response> {
		return this.http
			.delete(`${this.apiEndpoint}cabinet/${id}/cabinetusers/${CabinetUserID}`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
