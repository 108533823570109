import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DateTimeHelper } from '../../../../helpers';
import { Moment } from '../../../../tools';

@Component({
	selector: 'time-input-stepper',
	template: require('./time-input-stepper.component.html')
})

/**
 * Class representing the TimeInputStepperComponent component.
 */
export class TimeInputStepperComponent {

	/**
	 * {string} The input field name.
	 */
	@Input() name: string = '';

	/**
	 * {string} The time value.
	 */
	@Input() time: string = '09:00';

	/**
	 * {string} The minimum time value.
	 */
	@Input() minTime: string = '00:00';

	/**
	 * {string} The maximum time value.
	 */
	@Input() maxTime: string = '23:59';

	/**
	 * {number} The steps frequency (in minutes)
	 */
	@Input() stepMinutes: number = 5;

	/**
	 * {boolean} Wether the element is disabled
	 */
	@Input() disabled: boolean = false;

	/**
	 * {EventEmitter} The component event emitter.
	 */
	@Output() valueChange: EventEmitter<any> = new EventEmitter();

	/**
	 * Increase te input stepper's time value.
	 * @param {object} $event - The event
	 * @return {void}
	 */
	increaseTimeValue($event: any): void {
		$event.preventDefault();
		let { time, stepMinutes } = this;
		const minutes = DateTimeHelper.getMinutes(time) + stepMinutes;

		// Checks if maxTime is exceeded and emits new time value
		this.time = this.isTimeExceeding(true)
			? time : DateTimeHelper.getTime(minutes);
		this.valueChange.emit(this.time);
	}

	/**
	 * Decrease te input stepper's time value.
	 * @param {object} $event - The event
	 * @return {void}
	 */
	decreaseTimeValue($event: any): void {
		$event.preventDefault();
		let { time, stepMinutes } = this;
		const minutes = DateTimeHelper.getMinutes(time) - stepMinutes;

		// Checks if minTime is exceeded and emits new time value
		this.time = this.isTimeExceeding(false)
			? time : DateTimeHelper.getTime(minutes);
		this.valueChange.emit(this.time);
	}

	/**
	 * Emit a value change event to the parent component.
	 * @return {void}
	 */
	inputChange(): void {
		let { time, minTime, maxTime, stepMinutes } = this;

		// Checks for valid time string
		time = (!Moment(time, 'HH:mm', true).isValid()) ? minTime : time;

		// Keep time within allowed time range
		if (Moment(time, 'HH:mm', true) > Moment(maxTime, 'HH:mm', true)) {
			time = maxTime;
		}
		if (Moment(time, 'HH:mm', true) < Moment(minTime, 'HH:mm', true)) {
			time = minTime;
		}

		let minutes = DateTimeHelper.getMinutes(time);
		minutes = (minutes % stepMinutes !== 0)
			? Math.ceil(minutes / stepMinutes) * stepMinutes
			: minutes;

		// Checks if maxTime is exceeded
		this.time = this.isTimeExceeding(true)
			? time : DateTimeHelper.getTime(minutes);

		// Checks if minTime is exceeded
		this.time = this.isTimeExceeding(false)
			? time : DateTimeHelper.getTime(minutes);

		// Emits new time value (and forces string cast)
		this.valueChange.emit(this.time.toString());
	}

	/**
	 * Check if the current time is exceeding either minTime or maxTime
	 * @param {boolean} isMax
	 * @return {boolean}
	 */
	isTimeExceeding(isMax: boolean): boolean {
		const minutes = DateTimeHelper.getMinutes(this.time);
		return isMax
			? (minutes >= DateTimeHelper.getMinutes(this.maxTime))
			: (minutes <= DateTimeHelper.getMinutes(this.minTime));
	}
}
