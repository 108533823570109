import { Action } from '@ngrx/store';

export const ActionTypes = {
	EDIT_LAYOUT: '[Layout] EDIT_LAYOUT',
	TOGGLE_CATEGORIES: '[Layout] TOGGLE_CATEGORIES',
	TOGGLE_NAVIGATION: '[Layout] TOGGLE_NAVIGATION',
	TOGGLE_BOTTOM_BAR: '[Layout] TOGGLE_BOTTOM_BAR',
	EDIT_BOTTOM_BAR_ITEMS: '[Layout] EDIT_BOTTOM_BAR_ITEMS',
	RESET_STATE: '[Layout] RESET_STATE',
	SET_IS_LOADING: '[Layout] SET_IS_LOADING'
};

export class EditLayoutAction implements Action {
	readonly type = ActionTypes.EDIT_LAYOUT;
	constructor(public payload?: any) {}
}

export class ToggleCategoriesAction implements Action {
	readonly type = ActionTypes.TOGGLE_CATEGORIES;
	constructor(public payload?: any) {}
}

export class ToggleNavigationAction implements Action {
	readonly type = ActionTypes.TOGGLE_NAVIGATION;
	constructor(public payload?: any) {}
}

export class ToggleBottomBarAction implements Action {
	readonly type = ActionTypes.TOGGLE_BOTTOM_BAR;
	constructor(public payload?: any) {}
}

export class EditBottomBarItemsAction implements Action {
	readonly type = ActionTypes.EDIT_BOTTOM_BAR_ITEMS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| EditLayoutAction
	| ToggleCategoriesAction
	| ToggleNavigationAction
	| ToggleBottomBarAction
	| EditBottomBarItemsAction
	| SetIsLoadingAction;