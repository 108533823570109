import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Headers, Response } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackService } from '../../../../services/feedback/feedback.service';
import { UseTranslationPipe } from '../../../../pipes';

import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { StateInterface } from '../../../../store/state.model';
import { LayoutService, AuthenticationService, UsersService, User } from '../../../../services';
import { BaseComponent } from '../../../base/base.component';
import { _, getState } from '../../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./user-view.component.html')
})

/**
 * Class representing the RecipeViewComponent component.
 */
export class UserViewComponent extends BaseComponent implements OnInit {


	/**
	 * The recipe form.
	 * @type {FormGroup}
	 */
	form: FormGroup;

	/**
	 * The current recipe id.
	 * @type {number}
	 */
	userId: number;

	/**
	 * The current recipe (if applicable)
	 * @type {RecipeEnriched}
	 */
	user: User;

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private usersService: UsersService,
		private feedbackService: FeedbackService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private translate: TranslateService,
	) {
		super();

		// Subscribes to route params
		this.addSubscription(this.route.params.subscribe(params => {
			this.userId = +params['id'] || 0;
		}));

		if (this.userId) {
			this.usersService.getUsers().pipe(
				map((res: Response) => this.authenticationService.doStoreBearer(res)))
				.subscribe((data: ApiResponse) => {
					const { success, result } = data;
					let users: User[] = _.cloneDeep(result);
					this.user = users.find(item => item.ID === this.userId);

					if (this.form && this.user) {
						this.form.patchValue({
							firstName: this.user.FirstName,
							lastName: this.user.LastName,
							cardCode: this.user.CardCode,
							isBlacklisted: this.user.IsBlacklisted,
						});
					}
				}, (error: any) => {
					console.log('error', error);
				});
		}
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const getDefaultValue = userProperty => this.user ? this.user[userProperty] : '';

		window.scrollTo(0, 0);

		setTimeout(() => {
			this.store.dispatch(
				this.layoutService.setIsLoading(false)
			);
		}, 100);

		// Sets other form fields
		this.form = new FormGroup({
			firstName: new FormControl(getDefaultValue('FirstName'), Validators.required),
			lastName: new FormControl(getDefaultValue('LastName'), Validators.required),
			cardCode: new FormControl(getDefaultValue('CardCode')),
			isBlacklisted: new FormControl(getDefaultValue('IsBlacklisted'))
		});

		// Sets page header navigation
		this.store.dispatch(this.layoutService.editLayout({
			leftNav: {
				label: 'users-text',
				handler: () => this.router.navigate(['/management/users'])
			}
		})
		);
	}

	/**
	 * Return wether or not should focus on a specific field.
	 * @param {string} field
	 * @return {boolean}
	 */
	getShouldFocus(field: string): boolean {
		const { isValidated, form } = this;
		const fields: any = Object.keys(form.controls);

		let isOtherFieldsValid = true;

		// Checks if there are any invalid fields before this one
		fields.slice(0, fields.findIndex(control => control === field))
			.map((control) => {
				if (!form.get(control).valid) {
					isOtherFieldsValid = false;
				}
			});
		return (isValidated && isOtherFieldsValid && !form.get(field).valid);
	}

	/**
	 * Upon submitting the form.
	 * @return {void}
	 */
	onSubmit(event?: any): void {
		if (event) {
			event.preventDefault();
		}

		if (this.form.valid) {
			this.isValidated = true;
			this.isSubmitted = true;

			const useTranslation = new UseTranslationPipe(this.store);

			let userData: any = {
				FirstName: this.form.value.firstName,
				LastName: this.form.value.lastName,
				CardCode: this.form.value.cardCode,
				IsBlacklisted: this.form.value.isBlacklisted,
			};
			if (!this.userId) {
				userData.UserID = 'user' + Date.now();
			}

			if (this.user) {
				this.usersService.updateUser(this.userId, userData).subscribe((response: any) => {
					this.router.navigate(['/management/users']);
				}, (error: any) => {
					console.log('error', error);

					error = JSON.parse(error._body);
					this.store.dispatch(this.feedbackService.addNotification({
						text: useTranslation.transform(error.err)
					}));
				});
			} else {
				this.usersService.createUser(userData).subscribe((response: any) => {
					this.router.navigate(['/management/users']);
				}, (error: any) => {
					console.log('error', error);

					error = JSON.parse(error._body);
					this.store.dispatch(this.feedbackService.addNotification({
						text: useTranslation.transform(error.err)
					}));
				});
			}
		}
	}

	deleteUser(event?: any): void {
		if (event) {
			event.preventDefault();
		}

		this.translate.get('MANAGEMENT.USERS.USER.delete-confirmation-text').subscribe((translation: string) => {
			let isDeleteConfirmed: boolean = confirm(translation);
			if (isDeleteConfirmed) {
				this.usersService.deleteUser(this.userId).subscribe((response: any) => {
					this.router.navigate(['/management/users']);
				}, (error: any) => {
					console.log('Error', error);
				});
			}
		});
	}
}
