import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { Headers, Response } from '@angular/http';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';
import { ItemsHelper, ValidationHelper } from '../../../../../helpers';
import { LayoutService, AuthenticationService, CabinetsService, LocationsService } from '../../../../../services';
import { BaseComponent } from '../../../../base/base.component';
import { StateInterface, Locations, Cabinets, Categories, ManagementRecipes } from '../../../../../store/state.model';
import { tassign } from '../../../../../tools';
import { map } from 'rxjs/operators';


@Component({
	template: require('./availability-view.component.html')
})

/**
 * Class representing the CabinetAvailabilityViewComponent component.
 */
export class CabinetAvailabilityViewComponent extends BaseComponent implements OnInit {
	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;
	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;
	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	cabinetId: string = '';
	/**
	 * cabinet
	 */
	cabinet: any = null;
	/**
	 * Location
	 */
	location: any = null;
	/**
	 * availability
	 */
	locationAvailability: any = null;
	/**
	 * Is loading
	 * @type {boolean}
	 */
	isLoading: boolean = true;
	/**
	 * The availability form fields.
	 * @property {FormGroup} availabilityForm
	 */
	availabilityForm: FormGroup;

	/**
	 * The selected cabinet name for the subtitle.
	 * @type {string}
	 */
	cabinetSubtitle: string = '';

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private cabinetsService: CabinetsService,
		private authenticationService: AuthenticationService,
		private locationService: LocationsService,
		private formBuilder: FormBuilder,
	) {
		super();

		// Creates formbuilder instance.
		this.availabilityForm = this.formBuilder.group({});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.cabinetId !== id) {
				this.cabinetId = id;

				this.fetchCabinetById(id);
			}
		});
	}

	/**
	 * Sets cabinet and location based on the ID in the url.
	 * @param {string} id
	 */
	fetchCabinetById(id: string): void {
		this.cabinetsService.hydrateCabinet(id).pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { result } = data;

				if (result.length > 0) {
					this.cabinet = ItemsHelper.mapCabinetItems(result)[0];

					this.locationService.getLocationById(this.cabinet.locationId)
						.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
						.subscribe((location: ApiResponse) => {
							const { result } = location;

							if (result.length > 0) {
								this.location = result[0];

								// Set the subtitle for the overview
								this.cabinetSubtitle = this.cabinet.description;
							}

							this.checkIfLoadingIsComplete();
						});

					this.locationService.getLocationAvailabilityById(this.cabinet.locationId)
						.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
						.subscribe((locationAvailability: ApiResponse) => {
							const { result } = locationAvailability;

							if (result.length > 0) {
								this.locationAvailability = result.map(availability => {
									const controlName = `${availability.id}_`;
									const opensAt = availability.OpensAt !== null ? availability.OpensAt.substring(0, 5) : '00:00';
									const closesAt = availability.ClosesAt !== null ? availability.ClosesAt.substring(0, 5) : '00:00';

									// Create the formfields
									this.availabilityForm.addControl(controlName + 'opensAt', this.formBuilder.control(opensAt, [ValidationHelper.requiredValidator]));
									this.availabilityForm.addControl(controlName + 'closesAt', this.formBuilder.control(closesAt, [ValidationHelper.requiredValidator]));

									return {
										closesAt: closesAt,
										dayOfTheWeek: availability.DayOfTheWeek,
										locationID: availability.LocationID,
										minimumPeriodInMinutes: availability.MinimumPeriodInMinutes,
										opensAt: opensAt,
										id: availability.id,
									};
								});
							}

							this.checkIfLoadingIsComplete();
						});
				}
			});
	}

	/**
	 * Checks if all is loaded.
	 */
	checkIfLoadingIsComplete() {
		if (this.cabinet !== null && this.location !== null && this.locationAvailability !== null) {
			this.isLoading = false;
		}
	}

	/**
	 * Post login credentials if form is valid and submitted;
	 * @return {void}
	 */
	onSubmit(): void {
		this.isSubmitted = true;
		const defaultAvailability = {
			id: null,
		};

		if (this.availabilityForm.valid) {
			let payload = [];

			const controls = Object.keys(this.availabilityForm.controls).forEach(control => {
				const id = parseInt(control.split('_')[0]);
				let controlValue = this.availabilityForm.controls[control].value;

				if (controlValue === '23:59') {
					controlValue = '23:59:59';
				} else {
					controlValue += ':00';
				}

				// Check if the object with this ID already exists.
				if (payload.filter(obj => obj.id === id).length === 0) {

					let newValues = {
						id: id,
					};

					if (control.indexOf('opensAt') > -1) {
						newValues['OpensAt'] = controlValue;
					} else if (control.indexOf('closesAt') > -1) {
						newValues['ClosesAt'] = controlValue;
					}

					payload.push(newValues);

					// Already exists
				} else {
					payload.map(availability => {
						if (availability.id === id) {
							if (control.indexOf('opensAt') > -1) {
								availability['OpensAt'] = controlValue;
							} else if (control.indexOf('closesAt') > -1) {
								availability['ClosesAt'] = controlValue;
							}
						}

						return availability;
					});
				}
			});

			this.locationService.patchLocationAvailabilityById(this.cabinet.locationId, payload)
				.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
				.subscribe((location: ApiResponse) => {
					this.isLoading = false;
				});
		}
	}
}
