import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './feedback.actions';
import { FeedbackInterface as Feedback } from './feedback.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialFeedbackState: Feedback = {
	items: [],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Feedback} state - the current state
 * @return {Feedback} The new state
 */
export const feedback: ActionReducer<any, Actions> = (state: Feedback = initialFeedbackState, action: Actions): Feedback => {
	switch (action.type) {

		case ActionTypes.ADD_NOTIFICATION:
			return tassign(state, {
				items: [ ...state.items, action.payload]
			});

		case ActionTypes.DISMISS_NOTIFICATION:
			return tassign(state, {
				items: state.items
					.filter(notification => notification.id !== action.payload)
			});

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});

		case ActionTypes.RESET_STATE:
			return initialFeedbackState;
	}
	return state;
};
