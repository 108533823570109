import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StateInterface, Cabinets, Locale } from '../../../../../store/state.model';
import { LayoutService, AuthenticationService, CabinetsService, AuditlogService } from '../../../../../services';
import { BaseComponent } from '../../../../base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { _, tassign, getState, Moment } from '../../../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./auditlog-view.component.html')
})

/**
 * Class representing the CabinetAuditlogViewComponent component.
 */
export class CabinetAuditlogViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;
	/**
	 * Is loading or not?
	 * @type {boolean}
	 */
	isLoading: boolean = true;
	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	cabinetId: string = '';

	/**
	 * The auditlog response.
	 * @type {any}
	 */
	auditlog: any = null;
	/**
	 * The current selected language.
	 * @type {string}
	 */
	language: string = 'nl';

	/**
	 * The cabinets state.
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The selected cabinet name for the subtitle.
	 * @type {string}
	 */
	cabinetSubtitle: string = '';

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {AuditlogService} auditlog
	 * @param {AuthenticationService} authentication
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private AuditlogService: AuditlogService,
		private AuthenticationService: AuthenticationService,
		private store: Store<StateInterface>,
		// private cabinetsService: CabinetsService,
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
				const { current } = this.locale;
				this.language = current;
			})
		);

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.cabinetId !== id) {
				this.cabinetId = id;
				this.getAuditlog(id);
			}
		});
	}

	/**
	 * Loads the auditlog based on the ID in the url.
	 * @param {string} id
	 */
	getAuditlog(id: string): void {
		this.AuditlogService.loadAuditlog('cabinet', id, true).pipe(map((res: any) => this.AuthenticationService.doStoreBearer(res)))
		.subscribe((data: any) => {
			const { result } = data;

			this.isLoading = false;

			if (result.length > 0) {
				this.auditlog = result.map(auditlogLine => {
					return {
						DescrEN: auditlogLine.DescrEN,
						DescrNL: auditlogLine.DescrNL,
						MutationBy: auditlogLine.MutationBy !== null ? auditlogLine.MutationBy : '',
						MutationByUsergroup: auditlogLine.MutationByUsergroup !== null ? auditlogLine.MutationByUsergroup : 'unknown',
						MutationDT: auditlogLine.MutationDT,
						MutationType: auditlogLine.MutationType,
						NewValues: auditlogLine.NewValues,
						OldValues: auditlogLine.OldValues,
						TableName: auditlogLine.TableName,
					};
				});

				this.getCabinetNameById(id);
			}
		});
	}

	/**
	 * Sets cabinetSubtitle based on the ID.
	 * @param {string} id
	 */
	getCabinetNameById(id: string): void {
		const { items } = this.cabinets;

		let selectedCabinet = items.filter(cabinet => {
			if (parseInt(id) === cabinet.id) {
				return true;
			}
		});

		this.cabinetSubtitle = selectedCabinet[0].description;
	}
}
