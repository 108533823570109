import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { StateInterface, Feedback } from '../../../store/state.model';
import { FeedbackService } from '../../../services';
import { Moment } from '../../../tools';

@Component({
	selector: 'feedback',
	template: require('./feedback.component.html')
})

/**
 * Class representing the Feedback component.
 */
export class FeedbackComponent implements OnChanges {
	/**
	 * @type {Feedback}
	 */
	@Input() feedback: Feedback;
	/**
	 * @type {Array}  handled feedback uuids.
	 */
	uuids: any = [];

	/**
	 * Constructor.
	 * @param {FeedbackService} feedbackService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private feedbackService: FeedbackService,
		private store: Store<StateInterface>) {
	}

	/**
	 * ngOnChanges
	 */
	ngOnChanges(changes): void {
		const items = this.feedback.items;

		items.forEach((item) => {
			const isNewItem = typeof this.uuids[item.id] === 'undefined';

			if (isNewItem) {
				this.hideAfterTimeout(item);
			}
		});
	}

	/**
	 * Hide after timeout.
	 * @param  item - a feedback item.
	 * @return {void}
	 */
	hideAfterTimeout(item): void {
		const now = Moment();
		const diff = Moment.utc(Moment(item.expiresAt).diff(now)).format('s');
		const diffInMiliseconds = parseInt(diff) * 1000;
		let shouldHideFeedback = false;

		// if already passed
		if (diffInMiliseconds < 0) {
			this.onDismissNotification(item.id);
		} else {
			setTimeout(() => this.onDismissNotification(item.id), diffInMiliseconds);
		}

		this.uuids.push(item.id);
	}

	/**
	 * Dismiss a notification.
	 * @param {numer} notificationId
	 * @return {void}
	 */
	onDismissNotification(notificationId: number = 0): void {
		this.store.dispatch(
			this.feedbackService.dismissNotification(notificationId)
		);
	}
}
