import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './customnotifications.actions';
import { CustomNotificationsInterface as CustomNotifications } from './customnotifications.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialCustomNotificationsState: CustomNotifications = {
	items: [],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {CustomNotifications} state - the current state
 * @return {CustomNotifications} The new state
 */
export const customnotifications: ActionReducer<any, Actions> = (state: CustomNotifications = initialCustomNotificationsState, action: Actions): CustomNotifications => {
	switch (action.type) {

		case ActionTypes.LOAD_CUSTOMNOTIFICATIONS:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialCustomNotificationsState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
