import { FormGroup } from '@angular/forms';

/**
 * Class representing the Form helper.
 */
export class FormHelper {

	/**
	 * Return wether or not should focus on a specific field.
	 * @param {string} field
	 * @param {boolean} isValidated
	 * @param {FormGroup} form
	 * @return {boolean}
	 */
	static getShouldFocus(field: string, isValidated: boolean, form: FormGroup): boolean {
		const fields =  Object.keys(form.controls);
		let isOtherFieldsValid = true;

		// Checks if there are any invalid fields before this one
		fields.slice(0, fields.findIndex(control => control === field))
			.map(control => {
				if (!form.get(control).valid) {
					isOtherFieldsValid = false;
				}
			});
		return (isValidated && isOtherFieldsValid && !form.get(field).valid);
	}
}