import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { Filter } from '../../../../store/state.model';
import { Location } from '../../../../store/locations/locations.models';
import { Category } from '../../../../store/categories/categories.models';
import { initialFilterState } from '../../../../store/filter/filter.reducer';
import { DateTimeHelper } from '../../../../helpers';
import { getState } from '../../../../tools';

interface FilterPillLabels {
	category?: { nl: string; en: string; } | null;
	location?: { nl: string; en: string; } | null;
}

@Component({
	selector: 'filter-results',
	template: require('./filter-results.component.html')
})

/**
 * Class representing the FilterResultsComponent component.
 */
export class FilterResultsComponent implements OnChanges {

	/**
	 * {array} Current locations.
	 */
	@Input() locations: Array<Location>;

	/**
	 * {array} Current categories.
	 */
	@Input() categories: Array<Category>;

	/**
	 * {Filter} Current filter.
	 */
	@Input() filter: Filter;

	/**
	 * {number} The current number of results.
	 */
	@Input() noResults: number;

	/**
	 * {boolean} The recipes || categories || locations loading state.
	 */
	@Input() isLoading: boolean;

	/**
	 * {EventEmitter} The component removeFilter event emitter.
	 */
	@Output() removeFilter: EventEmitter<any> = new EventEmitter();

	/**
	 * {FilterPillLabels} The labels for the current filter.
	 */
	labels: FilterPillLabels = {};

	/**
	 * {boolean} Is the (filter) form touched or not?
	 */
	isTouched: boolean = false;

	/**
	 * Upon detecting changes.
	 * @return {void}
	 */
	ngOnChanges(): void {
		const { categories, locations, filter } = this;
		this.isTouched = JSON.stringify(initialFilterState) !== JSON.stringify(this.filter);

		if (categories.length && locations.length) {

			// Sets label for Category filter, if applicable
			this.labels.category = (filter.categoryId && filter.categoryId !== 'all') ? categories
				.find(category => category.id === filter.categoryId).name : null;

			// Sets label for Location filter, if applicable
			this.labels.location = filter.locationId ? locations
				.find(location => location.id === filter.locationId).name : null;
		}
	}

	/**
	 * Emit removeFilter event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onRemove($event: any): void {
		this.removeFilter.emit($event);
	}

	/**
	 * Don't show pills when filters have a default value.
	 * @param {string} type - The type of the pill
	 * @return {boolean}
	 */
	showFilterPill(type: string): boolean {
		const todayAsString = DateTimeHelper.getTodayAsString();
		const defaultTime = DateTimeHelper.getNearestReservationTimeForToday().format('HH:mm');
		const filterDate = this.filter.date;
		const isToday = todayAsString === filterDate || filterDate === '';

		if (type === 'date') {
			if (todayAsString === filterDate) {
				return false;
			}

		} else if (type === 'time') {
			if (this.filter.time === defaultTime && isToday) {
				return false;
			}
		}

		return true;
	}
}
