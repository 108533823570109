import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './locale.actions';
import { LocaleInterface as Locale } from './locale.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialLocaleState: Locale = {
	languages: [
		{ locale: 'nl', label: 'Nederlands' },
		{ locale: 'en', label: 'English' }
	],
	current: 'nl',
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Locale} state - the current state
 * @return {Locale} The new state
 */
export const locale: ActionReducer<any, Actions> = (state: Locale = initialLocaleState, action: Actions): Locale => {
	switch (action.type) {

		case ActionTypes.SET_CURRENT_LOCALE:
			return tassign(state, {
				current: action.payload
			});

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
