import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Extendable base class for smart components (based on http://bit.ly/2dQ0h4V).
 */
export class BaseComponent implements OnDestroy {

	/**
	 * {array} All subscriptions.
	 */
	protected subscriptions: Subscription[] = [];

	/**
	 * Class constructor.
	 * @return {void}
	 */
	constructor() {
		const f = this.ngOnDestroy;
		this.ngOnDestroy = () => {
			f();
			this.unsubscribeAll();
		};
	}

	/**
	 * Add a subscription.
	 * @access Protected
	 * @param {Subscription} subscription
	 * @return {Subscription}
	 */
	protected addSubscription(subscription: Subscription): Subscription {
		this.subscriptions.push(subscription);
		return subscription;
	}

	/**
	 * Unsubscribe from all subscriptions.
	 * @access Protected
	 * @return {void}
	 */
	protected unsubscribeAll(): void {
		this.subscriptions.map(subscription => {
			!subscription['isUnsubscribed'] && subscription.unsubscribe();
		});
	}

	/**
	 * Noop ngOnDestroy
	 * @return {void}
	 */
	ngOnDestroy(): void {
		//
	}
}
