import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './registration.actions';
import { RegistrationInterface as Registration } from './registration.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialRegistrationState: Registration = {
	recipeId: null,
	slotIndex: null,
	formData: {
		productName: '',
		userNote: '',
		destinationName: '',
		destinationEmail: '',
		locationId: null,
		dateTime: null,
		endDateTime: null
	},
	status: null,
	registrationType: null,
	isAdHoc: false,
	isSuccessful: false,
	isLoading: false,
	activeRegistrations: [],
};

/**
 * Reduce the store.
 * @param {Registration} state - the current state
 * @return {Registration} The new state
 */
export const registration: ActionReducer<any, Actions> = (state: Registration = initialRegistrationState, action: Actions): Registration => {
	switch (action.type) {

		case ActionTypes.EDIT_REGISTRATION:
			return tassign(state, action.payload);

		case ActionTypes.EDIT_FORMDATA:
			return tassign(state, {
				formData: tassign(state.formData, action.payload)
			});

		case ActionTypes.RESET_STATE:
			return { ...initialRegistrationState, isAdHoc: state.isAdHoc };

		case ActionTypes.RESET_SINGLE_REGISTRATION_STATE:
			return { ...initialRegistrationState, isAdHoc: state.isAdHoc, activeRegistrations: state.activeRegistrations };

		case ActionTypes.SET_IS_AD_HOC:
			return tassign(state, {
				isAdHoc: action.payload
			});

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});

		case ActionTypes.SET_ACTIVE_REGISTRATIONS:
			return tassign(state, {
				activeRegistrations: action.payload
			});
	}
	return state;
};
