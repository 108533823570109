import { Pipe, PipeTransform } from '@angular/core';

import { DateTimeHelper } from '../../helpers';

@Pipe({
	name: 'imageTimestamp'
})

/**
 * Class representing the TimePipe pipe.
 */
export class ImageTimestampPipe implements PipeTransform {

	/**
	 * Transforms time into another format.
	 * @param {number|string} time
	 * @param {string} parseTo
	 * @return {number}
	 */
	transform(imageUrl: string): string {
		if (imageUrl) {
			imageUrl += ((imageUrl.indexOf('?') === -1) ? '?' : '&') + 't=' + Date.now();
		}
		return imageUrl;
	}
}
