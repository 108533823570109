import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Headers, Response } from '@angular/http';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, ApplicationSettings, Locale } from '../../../store/state.model';
import { ApplicationSetting } from '../../../store/applicationsettings/applicationsettings.models';
import { LayoutService, AuthenticationService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';

@Component({
	template: require('./applicationsettings-view.component.html')
})

/**§
 * Class representing the ApplicationSettingsViewComponent component.
 */
export class ApplicationSettingsViewComponent extends BaseComponent {
	/**
	 * The cabinets state.
	 * @type {ApplicationSettings}
	 */
	applicationsettings: ApplicationSetting;

	/**
	 * @type {Locale} - The locale state.
	 */

	locale: Locale;

	/**
	 * The current selected language.
	 * @type {string}
	 */
	language: string = 'nl';

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
				const { current } = this.locale;
				this.language = current;
			})
		);

		// Subscribes to the applicationsettings state
		this.addSubscription(store.pipe(select('applicationsettings'))
			.subscribe((applicationsettings: ApplicationSettings) => {
				this.applicationsettings = _.cloneDeep(applicationsettings);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}
}