import { Injectable } from '@angular/core';

import { Actions, ActionTypes } from '../../store/filter/filter.actions';
import { StateInterface, Filter } from '../../store/state.model';
import { FilterInterface } from '../../store/filter/filter.models';
import { Moment, tassign } from '../../tools';
import { DateTimeHelper } from '../../helpers';

@Injectable()

/**
 * Class representing the Filter service.
 */
export class FilterService {
	/**
	 * Edit the current filter.
	 * @param {Filter} filter
	 * @return {Actions}
	 */
	editFilter(filter: Filter): Actions {
		return {
			type: ActionTypes.EDIT_FILTER,
			payload: filter
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}

	/**
	 * Sets the default location.
	 * @param {any} payload - The new state
	 * @return {Actions}
	 */
	setDefaultLocation(payload): Actions {
		return {
			type: ActionTypes.SET_DEFAULT_LOCATION,
			payload: payload
		};
	}

	/**
	 * Sets the opening times for the chosen location.
	 * @param  {string} date
	 * @param  {any[]} availabilities
	 * @return {Actions}
	 */
	setLocationOpeningTimes(date: string, availabilities: any[]): Actions {
		// Default openingtimes
		let daysClosed = [];
		let openingTimes: FilterInterface = {
			timeMin: '07:00',
			timeMax: '22:00',
			daysClosed: [],
		};
		const selectedDate = Moment(date, ['DD-MM-YYYY']);
		const selectedDay = selectedDate.weekday();
		const todayString = DateTimeHelper.getTodayAsString();
		const minimalAllowedTime = DateTimeHelper.getNearestReservationTimeForToday().format('HH:mm');

		// Check if there is availability on the selected date.
		if (availabilities.length > 0) {
			const availability = availabilities[0];
			const locationAvailability = availability.LocationAvailability;
			let availabilityOpeningTimes: FilterInterface = { ...openingTimes, daysClosed };

			// Check if the locationAvailability is filled.
			if (locationAvailability && locationAvailability.indexOf('|') > -1) {

				// Split on , because of multiple days.
				locationAvailability.split(',').forEach(dayString => {
					const daySplit = dayString.split('|');
					// Get day on sunday is 0 but api gives a 7.
					const day = parseInt(daySplit[0]);
					const weekday = day === 7 ? 0 : day;
					// Remove seconds
					const timeStart = daySplit[1].substring(0, 5);
					const timeEnd = daySplit[2].substring(0, 5);

					// When opening time matches closing time its closed.
					if (timeStart === timeEnd) {
						availabilityOpeningTimes.daysClosed.push(weekday);
					}

					// If this day matches the current day set values.
					if (weekday === selectedDay) {
						availabilityOpeningTimes.timeMin = timeStart;
						availabilityOpeningTimes.timeMax = timeEnd;

						// Also set time
						availabilityOpeningTimes.time = timeStart;
					}
				});

				openingTimes = tassign({}, openingTimes, availabilityOpeningTimes);
			}
		}

		if (date === todayString) {
			openingTimes.timeMin = minimalAllowedTime;
			openingTimes.time = minimalAllowedTime;
		}

		return {
			type: ActionTypes.SET_OPENING_HOURS,
			payload: openingTimes,
		};
	}
}
