import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { Actions, ActionTypes } from '../../store/management-recipes/management-recipes.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { _, getState, tassign } from '../../tools';

import 'rxjs/Rx';

export interface IRecipeRegistration {
	ID: number;
	CreateDT: string;
	DefectReported: boolean;
	DescrEN: string;
	DescrNL: string;
	DescriptionEN: string;
	DescriptionNL: string;
	EndDT: string;
	IsAdhoc: boolean;
	PutDT: string;
	RecipeUser: string;
	RegistrationType: number;
	RemindedICT: boolean;
	SecondPickupReminderSent: boolean;
	StartDT: string;
	Status: number;
	TakeDT: string;
}

@Injectable()

/**
 * Class representing the Recipes service.
 */
export class ManagementRecipesService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly.
	 * @param  {number}             locationId [description]
	 * @return {Observable}
	 */
	hydrateManagementRecipesByLocationID(locationId: number): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		let url: string = `${this.apiEndpoint}recipe/management/${locationId}`;
		return this.http.get(url, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly with unassigned recipes.
	 * @return {Observable}
	 */
	 hydrateUnassignedManagementRecipes(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		let url: string = `${this.apiEndpoint}recipe/management/unassigned`;
		return this.http.get(url, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly.
	 * @param  {number}             cabinetID [description]
	 * @return {Observable}
	 */
	newRecipe(recipe: any): Observable<any> {
		let url: string = `${this.apiEndpoint}recipe`;
		return this.httpToXhr('POST', url, recipe);
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly.
	 * @param  {number}             recipeID [description]
	 * @param  {any}                recipe [description]
	 * @return {Observable}
	 */
	updateRecipe(recipeId: number, recipe: any): Observable<any> {
		let url: string = `${this.apiEndpoint}recipe/` + recipeId;
		return this.httpToXhr('PATCH', url, recipe);
	}

	/**
	 * Get reservation history of a recipe
	 * @param  {number}             recipeID [description]
	 * @return {Observable}
	 */
	getRecipeRegistrations(recipeId: number): Observable<any> {
		let url: string = `${this.apiEndpoint}Registration/recipe/` + recipeId;
		return this.http.get(url, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Convert NgHttp request to XHR. Http does not support file transfer.
	 * @param  {string}             method Http method
	 * @param  {string}             url    Endpoint
	 * @param  {any}                recipe Recipe object
	 * @return {Observable}
	 */
	private httpToXhr(method: string, url: string, recipe: any): Observable<any> {
		return new Observable((observer: any) => {
			this.store.dispatch(this.setIsLoading(true));

			let xhr: XMLHttpRequest = new XMLHttpRequest();
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						observer.next(JSON.parse(xhr.response));
					} else {
						observer.error(xhr.response);
					}
				}
			};
			xhr.open(method, url, true);
			xhr.setRequestHeader('Authorization', getState(this.store).account.bearer);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

			let formData = new FormData();
			for (let key in recipe) {
				if (recipe.hasOwnProperty(key) && key !== 'Image') {
					formData.append(key, recipe[key]);
				}
			}
			if (recipe.Image) {
				formData.append('Image', recipe.Image, recipe.Image.name);
			}
			xhr.send(formData);
		});
	}

	/**
	 * Retrieve recipes data from server and dispatch action
	 * to update the store accordingly.
	 * @param  {number}             cabinetID [description]
	 * @return {Observable}
	 */
	deleteRecipe(recipeId: number): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		let url: string = `${this.apiEndpoint}recipe/` + recipeId;
		return this.http.delete(url, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to load recipes.
	 * @param {object} result
	 * @return {Actions}
	 */
	loadRecipes(result: any): Actions {
		const { managementRecipes } = getState(this.store);

		return {
			type: ActionTypes.LOAD_RECIPES,
			payload: tassign(managementRecipes, {
				items: ItemsHelper.mapRecipeItems(result),
				isLoading: false
			})
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'selected'.
	 * @param {null|number} to - The new state
	 * @return {Actions}
	 */
	setSelected(to: null | number): Actions {
		return {
			type: ActionTypes.SET_SELECTED,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
