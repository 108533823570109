import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'actions',
	template: require('./actions.component.html')
})

/**
 * Class representing the Actions component.
 */
export class ActionsComponent {

	/**
	 * @type {Boolean} - IsLoading state
	 */
	@Input() isLoading: boolean = false;

	/**
	 * @type {Boolean} - isDisabled state
	 */
	@Input() isDisabled: boolean = false;

	/**
	 * @type {string} - The continue button regular label
	 */
	@Input() continueButtonLabel: string = 'RESERVATION.GENERAL.continue-button-label';

	/**
	 * @type {string} - The continue button button type
	 */
	@Input() continueButtonType: string = 'button';

	/**
	 * @type {string} - The continue button loading state label
	 */
	@Input() continueButtonIsLoadingLabel: string = 'RESERVATION.GENERAL.continue-button-loading-label';

	/**
	 * @type {EventEmitter}
	 */
	@Output() onContinue: EventEmitter<any> = new EventEmitter();
}