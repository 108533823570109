import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Reducers, store and state
import { rootReducers, metaReducers } from '../../store/root.reducer';
import { InitialState } from '../../store/initial-state';

// Presentational components
import { TimeInputStepperComponent } from '../../components/reservation-view/ui/time-input-stepper/time-input-stepper.component';
import { SvgSpriteComponent } from '../../components/ui/svg-sprite/svg-sprite.component';
import { FeedbackComponent } from '../../components/ui/feedback/feedback.component';
import { FormFieldComponent } from '../../components/ui/form-field/form-field.component';
import { ActionsComponent } from '../../components/ui/actions/actions.component';
import { BottomBarComponent } from '../../components/ui/bottom-bar/bottom-bar.component';
import { PageHeaderComponent } from '../../components/reservation-view/page-header/page-header.component';
import { ProductListItemComponent } from '../../components/reservation-view/ui/product-list-item/product-list-item.component';
import { ProductListRemovableItemComponent } from '../../components/management-view/ui/product-list-removable-item/product-list-removable-item.component';

// Directives
import { Focus } from '../../directives';

// Pipes
import { TimePipe, ImageTimestampPipe, UseTranslationPipe, MomentPipe } from '../../pipes';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/i18n/', '.json'),
				deps: [HttpClient]}
		}),
		StoreModule.forRoot(
			rootReducers, {
				initialState: InitialState,
				metaReducers: metaReducers
			}
		),
		StoreDevtoolsModule.instrument({
			maxAge: 5
		}),
	],
	declarations: [
		Focus,
		TimePipe,
		ImageTimestampPipe,
		UseTranslationPipe,
		MomentPipe,
		TimeInputStepperComponent,
		SvgSpriteComponent,
		FeedbackComponent,
		FormFieldComponent,
		ActionsComponent,
		BottomBarComponent,
		PageHeaderComponent,
		ProductListItemComponent,
		ProductListRemovableItemComponent
	],
	providers: [],
	exports: [
		Focus,
		TimePipe,
		ImageTimestampPipe,
		UseTranslationPipe,
		MomentPipe,
		TimeInputStepperComponent,
		SvgSpriteComponent,
		FeedbackComponent,
		FormFieldComponent,
		ActionsComponent,
		BottomBarComponent,
		PageHeaderComponent,
		ProductListItemComponent,
		ProductListRemovableItemComponent
	]
})

export class SharedModule {}
