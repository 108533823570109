import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { Headers, Response } from '@angular/http';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';
import { ItemsHelper, ValidationHelper } from '../../../../../helpers';
import { LayoutService, AuthenticationService, CabinetsService } from '../../../../../services';
import { BaseComponent } from '../../../../base/base.component';
import { StateInterface, Locations, Cabinets, Categories, ManagementRecipes } from '../../../../../store/state.model';
import { map } from 'rxjs/operators';

@Component({
	template: require('./emailsettings-view.component.html')
})


/**
 * Class representing the CabinetLocationViewComponent component.
 */
export class CabinetEmailSettingsViewComponent extends BaseComponent implements OnInit {
	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;
	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	cabinetId: string = '';
	/**
	 * cabinet
	 */
	cabinet: any = null;
	/**
	 * Location
	 */
	location: any = null;
	/**
	 * The login form fields.
	 * @property {FormGroup} loginForm
	 */
	emailForm: FormGroup;

	/**
	 * Is loading
	 * @type {boolean}
	 */
	isLoading: boolean = true;

	/**
	 * The selected cabinet name for the subtitle.
	 * @type {string}
	 */
	cabinetSubtitle: string = '';

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @param {CabinetsService} cabinetsService
	 * @param {AuthenticationService} authenticationService
	 * @param {LocationsService} locationService
	 * @param {FormBuilder} formBuilder
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private cabinetsService: CabinetsService,
		private authenticationService: AuthenticationService,
		private formBuilder: FormBuilder,
	) {
		super();

		// Build a form
		this.emailForm = this.formBuilder.group({
			fromEmail: ['', [ValidationHelper.requiredValidator]],
			adminEmail: ['', [ValidationHelper.requiredValidator]],
			ICTEmail: ['', [ValidationHelper.requiredValidator]],
			reportEmail: ['', [ValidationHelper.requiredValidator]]
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.cabinetId !== id) {
				this.cabinetId = id;
				this.fetchCabinetById(id);
			}
		});
	}

	/**
	 * Sets cabinet and location based on the ID in the url.
	 * @param {string} id
	 */
	fetchCabinetById(id: string): void {
		this.cabinetsService.hydrateCabinet(id).pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { result } = data;

				if (result.length > 0) {
					this.cabinet = ItemsHelper.mapCabinetItems(result)[0];

					// Set the subtitle for the overview
					this.cabinetSubtitle = this.cabinet.description;

					// Patch ze values
					this.emailForm.patchValue({
						fromEmail: this.cabinet.fromEmail,
						adminEmail: this.cabinet.adminEmail,
						ICTEmail: this.cabinet.ICTEmail,
						reportEmail: this.cabinet.reportEmail,
					});

					this.isLoading = false;
				}
			});
	}

	/**
	 * Post login credentials if form is valid and submitted;
	 * @return {void}
	 */
	onSubmit(): void {
		this.isSubmitted = true;

		if (this.emailForm.valid) {
			this.isLoading = true;

			const payload = {
				fromEmail: this.emailForm.controls['fromEmail'].value,
				adminEmail: this.emailForm.controls['adminEmail'].value,
				ICTEmail: this.emailForm.controls['ICTEmail'].value,
				reportEmail: this.emailForm.controls['reportEmail'].value,
			};

			this.cabinetsService.patchCabinetById(this.cabinetId, payload)
				.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
				.subscribe((location: ApiResponse) => {
					this.isLoading = false;
				});
		}
	}
}
