import { Action } from '@ngrx/store';

export const ActionTypes = {
	SET_LOGIN_URL: 'SET_LOGIN_URL',
	RESET_STATE: 'RESET_STATE',
	SET_IS_LOADING: 'SET_IS_LOADING'
};

export class SetLoginUrlAction implements Action {
	readonly type = ActionTypes.SET_LOGIN_URL;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| SetLoginUrlAction
	| SetIsLoadingAction;