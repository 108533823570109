import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_RECIPES: '[Management Recipes] LOAD_RECIPES',
	RESET_STATE: '[Management Recipes] RESET_STATE',
	SET_SELECTED: '[Management Recipes] SET_SELECTED',
	SET_IS_LOADING: '[Management Recipes] SET_IS_LOADING',
	ADD_RECIPE: '[Management Recipes] ADD_RECIPE',
	UPDATE_RECIPE: '[Management Recipes] UPDATE_RECIPE',
	DELETE_RECIPE: '[Management Recipes] DELETE_RECIPE',
};

export class LoadRecipesAction implements Action {
	readonly type = ActionTypes.LOAD_RECIPES;
	constructor(public payload?: any) {}
}

export class SetSelectedAction implements Action {
	readonly type = ActionTypes.SET_SELECTED;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class AddRecipeAction implements Action {
	readonly type = ActionTypes.ADD_RECIPE;
	constructor(public payload?: any) {}
}

export class UpdateRecipeAction implements Action {
	readonly type = ActionTypes.UPDATE_RECIPE;
	constructor(public payload?: any) {}
}

export class DeleteRecipeAction implements Action {
	readonly type = ActionTypes.DELETE_RECIPE;
	constructor(public payload?: any) {}
}
export type Actions =
	| LoadRecipesAction
	| SetSelectedAction
	| SetIsLoadingAction
	| AddRecipeAction
	| UpdateRecipeAction
	| DeleteRecipeAction;