import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_RECIPES: '[Recipes] LOAD_RECIPES',
	RESET_STATE: '[Recipes] RESET_STATE',
	SET_SELECTED: '[Recipes] SET_SELECTED',
	SET_LAST_UPDATED: '[Recipes] SET_LAST_UPDATED',
	SET_IS_LOADING: '[Recipes] SET_IS_LOADING',
	ADD_AVAILABILITY: '[Recipes] ADD_AVAILABILITY',
	SET_FILTERED_RECIPES: '[Recipes] SET_FILTERED_RECIPES'
};

export class LoadRecipesAction implements Action {
	readonly type = ActionTypes.LOAD_RECIPES;
	constructor(public payload?: any) {}
}

export class SetSelectedAction implements Action {
	readonly type = ActionTypes.SET_SELECTED;
	constructor(public payload?: any) {}
}

export class SetLastUpdatedAction implements Action {
	readonly type = ActionTypes.SET_LAST_UPDATED;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class AddAvailability implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class SetFilteredRecipes implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadRecipesAction
	| SetSelectedAction
	| SetLastUpdatedAction
	| SetIsLoadingAction
	| AddAvailability
	| SetFilteredRecipes;