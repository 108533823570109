import { Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { StateInterface, Registration, Account } from '../../../store/state.model';
import { RecipesAvailabilityItem, RecipeEnriched } from '../../../store/recipes/recipes.models';
import { Location } from '../../../store/locations/locations.models';
import { LayoutService, RegistrationService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState, Moment } from '../../../tools';

interface RegistrationEnriched extends Registration {
	location: Location | null;
}

@Component({
	template: require('./confirmation-view.component.html')
})

/**
 * Class representing the ConfirmationViewComponent component.
 */
export class ConfirmationViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {RegistrationEnriched} - The registration state snapshot.
	 */
	registration: RegistrationEnriched;

	/**
	 * @type {Account} - The account state snapshot.
	 */
	account: Account;

	/**
	 * @type {RecipeEnriched} - The current recipe.
	 */
	recipe: RecipeEnriched;

	/**
	 * @type {RecipesAvailabilityItem} - The current slot.
	 */
	slot: RecipesAvailabilityItem;

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {LayoutService} layoutService
	 * @param {RegistrationService} registrationService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private layoutService: LayoutService,
		private registrationService: RegistrationService,
		private router: Router,
		private store: Store<StateInterface>) {
		super();

		const { registration, account, recipes, categories, locations } = getState(store);

		if (registration && locations) {
			const { formData, isAdHoc, isSuccessful } = registration;

			// Redirects when no registration was made
			if (!isSuccessful && !account.isLoggedIn) {
				this.router.navigate(['/reservation/filter']);
				return;
			}

			// Sets registration state snapshot + enriched metadata
			this.registration = {
				..._.cloneDeep(registration),

				// Adds location metadata
				location: formData.locationId ? locations.items
					.find(location => location.id === formData.locationId) : null
			};
		}

		// Sets account state snapshot
		this.account = _.cloneDeep(account);

		if (recipes) {

			// Finds current recipe
			const recipe = recipes.items
				.find(recipe => recipe.id === registration.recipeId);

			if (recipe) {

				// Adds meta data
				this.recipe = tassign(recipe, {

					// Adds category metadata
					category: categories.items
						.find(category => category.id === recipe.categoryId),

					// Adds location metadata
					location: locations.items
						.find(location => location.id === recipe.locationId)
				});

				// Sets slot
				this.slot = recipe.availability[registration.slotIndex];
			}
		}
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const { registrationType, isAdHoc } = this.registration;
		const handler = () => {
			this.store.dispatch(
				this.registrationService.resetState()
			);

			// Navigates back
			this.router.navigate(registrationType === 0
				? ['/reservation/overview']
				: ['/reservation/ict-box']
			);
		};

		window.scrollTo(0, 0);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				rightNav: null,
				isBottomBarVisible: !isAdHoc,
				leftNav: isAdHoc ? null : registrationType === 0
				? {
					label: 'overview-text',
					handler
				} : {
					label: 'report-ict-box-item-text',
					handler
				}
			})
		);

		// Disables isSuccessful
		this.store.dispatch(
			this.registrationService.editRegistration(tassign(this.registration, {
				isSuccessful: false
			}))
		);

		// Resets full application state if applicable
		if (isAdHoc) {
			sessionStorage.removeItem('stateSnapshot');
			this.store.dispatch({ type: 'ACTION_RESET_FULL_STATE' });

			setTimeout(() => {
				this.router.navigate(['/exit']);
			}, 10000);
		}
	}
}
