import { Injectable, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../auth/authentication.service';
import { Actions, ActionTypes } from '../../store/registration/registration.actions';
import { StateInterface, Account, Registration, RegistrationItem } from '../../store/state.model';
import { HeadersHelper, DateTimeHelper } from '../../helpers';
import { _, getState, tassign, Moment } from '../../tools';

import 'rxjs/Rx';

interface RegistrationPost {
	startDT: number;
	endDT: number;
	Status: number;
	RegistrationType: number;
	RecipeID?: number | null;
	LocationID?: number | null;
	SourceEmail?: string;
	DestinationEmail?: string;
	DestinationName?: string;
	UserNote?: string;
	isAdHoc?: boolean;
	cabinetID?: boolean;
}

@Injectable()

/**
 * Class representing the Registration service.
 */
export class RegistrationService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {AuthenticationService} authenticationService
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private authenticationService: AuthenticationService,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Performs a call to submit a registration.
	 * @return {Observable}
	 */
	submitRegistration(): Observable<any> {
		const { registration, recipes } = getState(this.store);
		const { recipeId, formData, status, registrationType } = registration;
		const { productName, userNote, destinationName, destinationEmail, locationId, dateTime, endDateTime } = formData;

		// Sets body (maps state to parameters)
		const body: RegistrationPost = {
			startDT: dateTime,
			endDT: endDateTime,
			Status: status, // @TODO will be removed (ask Rick)
			RegistrationType: registrationType,
			RecipeID: recipeId || null,
			LocationID: locationId || null,
			DestinationEmail: destinationEmail || null,
			DestinationName: destinationName || null,
			UserNote: userNote || null,
			isAdHoc: !!sessionStorage.getItem('cabinetID'),
			cabinetID: JSON.parse(sessionStorage.getItem('cabinetID') || 'null')
		};

		// Posts data
		return this.http
			.post(`${this.apiEndpoint}registration/create`, body, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to get cancellable registrations.
	 * @return {Observable}
	 */
	getCancellableRegistrations(): Observable<any> {
		// Posts data
		return this.http
			.get(`${this.apiEndpoint}registration/cancellable`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to get registrations.
	 * @return {Observable}
	 */
	getActiveRegistrations(): Observable<any> {
		return this.http
			.get(`${this.apiEndpoint}registration/active`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to cancel a registration.
	 * @return {Observable}
	 */
	cancelRegistration(registrationId: number): Observable<any> {
		// Posts data
		return this.http
			.delete(`${this.apiEndpoint}registration/cancel/` + registrationId, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to update a registration.
	 * @return {Observable}
	 */
	updateRegistration(registrationId: number, data: any): Observable<any> {
		// Posts data
		return this.http
			.patch(`${this.apiEndpoint}registration/` + registrationId, data, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to edit the current registration.
	 * @return {Actions}
	 */
	editRegistration(data: Registration): Actions {
		return {
			type: ActionTypes.EDIT_REGISTRATION,
			payload: data
		};
	}

	/**
	 * Dispatch an action to edit the state for 'formData'.
	 * @param {object} data - Form data
	 * @return {Actions}
	 */
	editFormData(data: any): Actions {
		return {
			type: ActionTypes.EDIT_FORMDATA,
			payload: data
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetReservation(): Actions {
		return {
			type: ActionTypes.RESET_SINGLE_REGISTRATION_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isAdHoc'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsAdHoc(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_AD_HOC,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}

	/**
	 * Dispatch an action to set the active registrations.
	 * @param {Array<RegistrationItem>} activeRegistrations
	 * @return {Actions}
	 */
	setActiveReservations(activeRegistrations: Array<RegistrationItem>): Actions {
		/**
		 * @WARNING: Because of timezone differences between servers we have to remove the timezone from the payload.
		 */

		return {
			type: ActionTypes.SET_ACTIVE_REGISTRATIONS,
			payload: activeRegistrations,
		};
	}
}
