import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_LOCATIONS: '[Locations] LOAD_LOCATIONS',
	RESET_STATE: '[Locations] RESET_STATE',
	SET_IS_LOADING: '[Locations] SET_IS_LOADING'
};

export class LoadLocationsAction implements Action {
	readonly type = ActionTypes.LOAD_LOCATIONS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadLocationsAction
	| SetIsLoadingAction;