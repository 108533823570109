import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../../../store/state.model';
import { Moment, getState } from '../../../../../tools';

import 'moment/min/locales';

interface FormattedDate {
	day?: string;
	dayNumber?: number;
	month?: string;
}

@Component({
	selector: 'slot-date',
	template: require('./slot-date.component.html')
})

/**
 * Class representing the SlotDateComponent component.
 */
export class SlotDateComponent implements OnInit {

	/**
	 * {string} The label for the filter pill.
	 */
	@Input() date: string;

	/**
	 * {FormattedDate} The formatted date data.
	 */
	formattedDate: FormattedDate = {};

	/**
	 * {function} The getState method.
	 */
	getState: any = getState;

	/**
	 * Constructor.
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private store: Store<StateInterface>) {
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const { current } = this.getState(this.store).locale;

		Moment.locale(current || 'nl');
		const date = Moment(this.date, 'YYYY-MM-DD');

		// Formats the date
		this.formattedDate = {
			day: date.format('dd'),
			dayNumber: parseInt(date.format('DD')),
			month: date.format('MM')
		};
	}
}
