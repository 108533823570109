import { uuidv4 } from '../../tools';

/**
 * Class representing the DateTimeHelper helper.
 */
export class UuidHelper {
	/**
	 * Generates a uuid
	 * @return {string} a unique uuid
	 */
	static generate(): string {
		return uuidv4();
	}
}
