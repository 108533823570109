import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

// Custom modules
import { SharedModule } from '../shared/shared.module';

// Reducers, store and state
import { rootReducers, metaReducers } from '../../store/root.reducer';
import { InitialState } from '../../store/initial-state';

// Container components
import { OnboardingViewComponent } from '../../components/onboarding-view/onboarding-view.component';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/i18n/', '.json'),
				deps: [HttpClient]}
		}),
		StoreModule.forRoot(
			rootReducers, {
				initialState: InitialState,
				metaReducers: metaReducers
			}
		),
		SharedModule
	],
	declarations: [
		OnboardingViewComponent,
	],
	providers: [
	]
})

export class OnboardingModule {}
