import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { Actions, ActionTypes } from '../../store/auth/auth.actions';
import { StateInterface, Account } from '../../store/state.model';
import { FeedbackService } from '../feedback/feedback.service';
import { HeadersHelper } from '../../helpers';
import { UseTranslationPipe } from '../../pipes';
import { _, getState, tassign } from '../../tools';

import 'rxjs/Rx';

interface AuthenticationPost {
	username: string;
	password: string;
}

@Injectable()

/**
 * Class representing the Authentication service.
 */
export class AuthenticationService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {FeedbackService} feedbackService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private feedbackService: FeedbackService,
		private store: Store<StateInterface>) {
	}

	/**
	 * Dispatch an action to store the bearer retrieved from call headers and return retrieved data.
	 * @param {object} response
	 * @return {void}
	 */
	doStoreBearer(response: any): any {
		const { headers } = response;
		const bearer = headers.get('authorization');

		if (bearer) {
			this.store.dispatch(
				this.storeBearer(bearer)
			);

			// Returns retrieved data
			return response.json();
		}
	}

	/**
	 * Performs a call to authenticate the user and returns relevant profile data.
	 * @param {AuthenticationPost} params - username and password
	 * @return {Observable}
	 */
	doPostValidate(params: AuthenticationPost): Observable<Response> {

		// Sets body
		const body = { ...params };

		// Sets headers
		const options = new RequestOptions({
			headers: new Headers({ 'Content-Type': 'application/json' })
		});

		this.store.dispatch(this.setIsLoading(true));
		return this.http.post(`${this.apiEndpoint}auth/validate`, body, options);
	}

	/**
	 * Performs a call to authenticate the user through their MyFair card code.
	 * @param {string} cardCode
	 * @return {Observable}
	 */
	doPostValidateCard(cardCode: string): Observable<Response> {

		// Sets headers
		const options = new RequestOptions({
			headers: new Headers({ 'Content-Type': 'application/json' })
		});

		this.store.dispatch(this.setIsLoading(true));
		return this.http.post(`${this.apiEndpoint}auth/validatecard`, { cardCode }, options);
	}

	/**
	 * Performs a call to authenticate the user through a previously retrieved bearer token (e.g. through SAML).
	 * @return {Observable}
	 */
	doPostCheckToken(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http.post(`${this.apiEndpoint}auth/checktoken`, null, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to accept the conditions
	 * @return {Observable}
	 */
	doPostAcceptConditions(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http.post(`${this.apiEndpoint}auth/acceptConditions`, null, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an actiom to load a bearer token.
	 * @param {string} token
	 * @return {Actions}
	 */
	storeBearer(token: string): Actions {
		return {
			type: ActionTypes.STORE_BEARER,
			payload: token
		};
	}

	/**
	 * Dispatch an action to change the state for 'isNew'.
	 * @param {boolean} to
	 * @return {Actions}
	 */
	setIsNew(to: boolean): Actions {

		// Sets or removes sessionStorage key
		!to ? sessionStorage.setItem('isNotNew', 'true')
			: sessionStorage.removeItem('isNotNew');

		return {
			type: ActionTypes.SET_IS_NEW,
			payload: to
		};
	}

	/**
	 * Load an account into the store (typically with data
	 * retrieved from the API).
	 * @param {Account} data
	 * @return {Actions}
	 */
	loadAccount(data: Account): Actions {
		const { account, locale } = getState(this.store);
		const { profile } = data;

		return {
			type: ActionTypes.LOAD_ACCOUNT,
			payload: tassign(account, {
				...data,
				formData: {
					...account.formData,
					username: profile.email // @TODO check if profile.email is right
				},
				isLoading: false
			})
		};
	}

	/**
	 * Handle an api error, throw a notification and fire a handler method if applicable.
	 * @param {object} error
	 * @param {function} handler
	 * @return {Actions}
	 */
	doHandleError(error: any, handler?: any): Actions {
		const { ok, status, statusText, _body } = error;
		const useTranslation = new UseTranslationPipe(this.store);

		// Retrieves error message (if available)
		const message = error.message || typeof _body === 'string' ? (body => {
			const { message, err } = JSON.parse(body);
			return message || err;
		})(_body) : undefined;

		handler ? handler() : null;

		return this.feedbackService.addNotification({
			status, text: (message || statusText) || useTranslation.transform({
				en: 'An unknown error has occured.',
				nl: 'Er is een onbekende fout opgetreden.'
			})
		});
	}

	/**
	 * Dispatch an action to edit the state for 'formData'.
	 * @param {object} data - Form data
	 * @return {Actions}
	 */
	editFormData(data: any): Actions {
		return {
			type: ActionTypes.EDIT_FORMDATA,
			payload: data
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setConditionsRead(to: boolean): Actions {
		return {
			type: ActionTypes.SET_CONDITIONS_READ,
			payload: to
		};
	}
}
