import { Headers, RequestOptions } from '@angular/http';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../store/state.model';
import { getState } from '../../tools';

/**
 * Class representing the Headers helper.
 */
export class HeadersHelper {

	/**
	 * {function} Retrieve a snapshot of the current state.
	 */
	static getState = getState;

	/**
	 * Get options for a http call.
	 * @param {Store} store
	 * @return {RequestOptions}
	 */
	static getOptions(store: Store<StateInterface>): RequestOptions {
		const { bearer } = this.getState(store).account;

		let headers = new Headers({ 'Content-Type': 'application/json' });
		headers.append('authorization', bearer || undefined);
		return new RequestOptions({ headers });
	}
}