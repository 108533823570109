import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, Locations, Cabinets, Categories, ManagementRecipes } from '../../../store/state.model';
import { ManagementRecipeEnriched } from '../../../store/management-recipes/management-recipes.models';
import { Cabinet } from '../../../store/cabinets/cabinets.models';
import { LayoutService, AuthenticationService } from '../../../services';
import { RecipesHelper } from '../../../helpers/recipes/recipes.helper';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';

@Component({
	template: require('./cabinets-view.component.html')
})

/**
 * Class representing the CabinetsViewComponent component.
 */
export class CabinetsViewComponent extends BaseComponent {

	/**
	 * The cabinets state.
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The recipes state.
	 * @type {Recipes}
	 */
	filteredCabinets: Array<Cabinet>;

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
				this.filteredCabinets = this.cabinets.items.filter(cabinet => {
					return cabinet.showInManagement;
				});
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		const { items } = this.cabinets;

		this.filteredCabinets = items.filter(category => {
			const { description } = category;
			const hasQuery = (haystack, needle) => haystack ? haystack.search(new RegExp(needle, 'i')) !== -1 : false;

			if (query) {

				// Searches recipe metadata
				let inDescription = hasQuery(description, query);
				let inCategory = category ? hasQuery(category.description, query) : false;

				return (inDescription || inCategory) || false;
			}
			return true;
		});
	}
}
