import { Action } from '@ngrx/store';

export const ActionTypes = {
	SET_CURRENT_LOCALE: '[Locale] SET_CURRENT_LOCALE',
	SET_IS_LOADING: '[Locale] SET_IS_LOADING'
};

export class SetCurrentLocaleAction implements Action {
	readonly type = ActionTypes.SET_CURRENT_LOCALE;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| SetCurrentLocaleAction
	| SetIsLoadingAction;