import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../store/state.model';
import { getState } from '../../tools';

interface Translations {
	nl: string;
	en: string;
}

@Pipe({
	name: 'useTranslation'
})

/**
 * Class representing the UseTranslation pipe.
 */
export class UseTranslationPipe implements PipeTransform {

	/**
	 * {string} The current locale.
	 */
	private currentLocale: string;

	/**
	 * {function} Retrieve a snapshot of the current state.
	 */
	private getState: any;

	/**
	 * Constructor.
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private store: Store<StateInterface>) {
		this.getState = getState;
	}

	/**
	 * Transforms item into its appropriate locale string.
	 * @access Public
	 * @param {Translations} item
	 * @return {string}
	 */
	transform(item: Translations): string {
		const { locale } = this.getState(this.store);
		const { languages, current } = locale;

		// Sets current locale (and thus language)
		this.currentLocale = languages
			.find(language => language.locale === current).locale;

		// Returns translation
		if (item) {
			return item[this.currentLocale];
		} else {
			return '...';
		}
	}
}
