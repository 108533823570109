import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { StateInterface, Account, Layout } from '../../store/state.model';
import { LayoutService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { _ } from '../../tools';

@Component({
	template: require('./reservation-view.component.html'),
})

/**
 * Class representing the ReservationView component.
 */
export class ReservationViewComponent extends BaseComponent {

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Layout} - The layout state.
	 */
	layout: Layout;

	/**
 	 * Class constructor.
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private store: Store<StateInterface>) {
		super();
		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);
		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);
			})
		);
	}
}
