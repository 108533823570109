import { Component, Input, Output, OnInit, OnChanges, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';

import { Filter, Layout } from '../../../../store/state.model';
import { Location } from '../../../../store/locations/locations.models';
import { Category } from '../../../../store/categories/categories.models';
import { Recipe, RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { initialFilterState } from '../../../../store/filter/filter.reducer';
import { BaseComponent } from '../../../base/base.component';
import { _, Moment } from '../../../../tools';
import { DateTimeHelper } from '../../../../helpers';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	selector: 'filter-form',
	template: require('./filter-form.component.html')
})

/**
 * Class representing the FilterFormComponent component.
 */
export class FilterFormComponent extends BaseComponent implements OnInit, OnChanges {

	/**
	 * @type {ElementRef} - The element on which to bind the datepicker.
	 */
	@ViewChild('dateInput', { static: false }) dateInput: ElementRef;

	/**
	 * @type {boolean} - The filter mode.
	 */
	@Input() isActive: boolean;

	/**
	 * @type {array} - Current locations.
	 */
	@Input() locations: Array<Location>;

	/**
	 * @type {array} - Current categories.
	 */
	@Input() categories: Array<Category>;

	/**
	 * @type {array} - Current recipes.
	 */
	@Input() recipes: Array<RecipeEnriched>;

	/**
	 * @type {Layout} - Current layout state.
	 */
	@Input() layout: Layout;

	/**
	 * @type {string} - Current locale.
	 */
	@Input() locale: string;

	/**
	 * @type {Filter} - Current filter.
	 */
	@Input() filter: Filter;

	/**
	 * @type {boolean} - isAdHoc registration
	 */
	@Input() isAdHoc: boolean;

	/**
	 * @type {number} - The number of results for the current filter.
	 */
	@Input() noResults: number = 0;

	/**
	 * @type {EventEmitter} - The component apply event emitter.
	 */
	@Output() apply: EventEmitter<any> = new EventEmitter();

	/**
	 * @type {EventEmitter} - The component toggleIsCategoriesExpanded event emitter.
	 */
	@Output() toggleIsCategoriesExpanded: EventEmitter<any> = new EventEmitter();

	/**
	 * @type {FormControl} - The search query field.
	 */
	query: FormControl = new FormControl();

	/**
	 * @type {boolean} - Is the form touched or not?
	 */
	isTouched: boolean = false;

	/**
	 * @type {object} - The datePicker element.
	 */
	datePicker: any;

	/**
	 * @type {string} - The prettyDate in case there is no availability.
	 */
	fallbackDateValue: any;
	/**
	 * @type {string} - Min time for time stepper.
	 */
	minTime: string = '07:00';
	/**
	 * @type {string} - Max time for time stepper.
	 */
	maxTime: string = '22:00';
	/**
	 * @type {number[]} - weekdays on which this location is closed
	 */
	daysClosed: number[] = [];

	/**
	 * Class constructor.
	 * @param {Router} router
	 * @return {void}
	 */
	constructor(
		private router: Router) {
		super();
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {

		// Adds debouncer to the query input field
		this.addSubscription(this.query.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged())
			.subscribe(value => {
				this.onApply({ query: value });
			})
		);
	}

	/**
	 * Upon changes detected.
	 * @return {void}
	 */
	ngOnChanges(): void {
		this.isTouched = this.isTouched || JSON
			.stringify(initialFilterState) !== JSON
			.stringify(this.filter);

		if (!this.isAdHoc) {
			const selectedDate = this.filter.date;

			this.fallbackDateValue = selectedDate;

			const flatPickrConfig = {
				dateFormat: 'd-m-Y',
				altFormat: 'd-m-Y',
				altInput: true,
				defaultDate: selectedDate,

				// Enables currently available dates
				minDate: Moment().toDate(),
				maxDate: Moment().add(30, 'day').toDate(),

				// On changing (and thus selecting) the date
				onChange: (selectedDates, dateStr, instance) => {
					this.onApply({ date: dateStr });
				},
				// Todo bind this to location opening times.
				'disable': [
					this.checkIfDateShouldBeDisabled.bind(this),
				],
			};

			setTimeout(() => {
				this.datePicker = new Flatpickr(
					this.dateInput.nativeElement,	this.locale === 'nl'
						? { ...flatPickrConfig, locale: Dutch } : flatPickrConfig
				);
			});
		}

		this.checkTimeInputValues();
	}

	/**
	 * Check if date should be disabled.
	 * @param  {Date} date
	 * @return {boolean}
	 */
	checkIfDateShouldBeDisabled(date): boolean {
		return this.daysClosed.indexOf(date.getDay()) > -1;
	}

	/**
	 * Updates the minTime, maxTime and daysClosed. You shall not book recipes in the past.
	 */
	checkTimeInputValues(): void {
		this.minTime = this.filter.timeMin;
		this.maxTime = this.filter.timeMax;
		this.daysClosed = this.filter.daysClosed;
	}

	/**
	 * Emit apply event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onApply($event: any): void {
		this.apply.emit($event);
	}

	/**
	 * Emit toggleIsCategoriesExpanded event to the parent component.
	 * @param {object} $event
	 * @return {void}
	 */
	onToggleIsCategoriesExpanded($event: any): void {
		this.toggleIsCategoriesExpanded.emit($event);
	}

	/**
	 * On submitting the form, route to /overview.
	 * @return {void}
	 */
	onSubmit(): void {
		this.router.navigate(['/reservation/overview']);
	}
}
