import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_CABINETS: '[Cabinets] LOAD_CABINETS',
	RESET_STATE: '[Cabinets] RESET_STATE',
	SET_IS_LOADING: '[Cabinets] SET_IS_LOADING'
};

export class LoadCabinetsAction implements Action {
	readonly type = ActionTypes.LOAD_CABINETS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadCabinetsAction
	| SetIsLoadingAction;