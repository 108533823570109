import { Store } from '@ngrx/store';
import * as Moment from 'moment';
import { take } from 'rxjs/operators';
import { StateInterface } from '../store/state.model';
import 'moment/min/locales';

export { Moment };
export { tassign } from 'tassign';
export const _ = require('lodash');
export const uuidv4 = require('uuid/v4');

/**
 * See https://github.com/ngrx/store, the part below
 * 'Migrating from Store v1.x' for more information on
 * why @ngrx removed the getStore method from their API.
 * @param {Store} store
 * @return {StateInterface} state
 */
export const getState = (store: Store<StateInterface>): StateInterface => {
	let state: StateInterface;

	store.pipe(take(1)).subscribe(s => state = s);
	return _.cloneDeep(state);
};
