import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { StateInterface, Account } from '../../../store/state.model';
import { AuthenticationService, LayoutService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { Moment, getState } from '../../../tools';

@Component({
	selector: 'conditions',
	template: require('./conditions-view.component.html')
})

/**
 * Class representing the ConditionsViewComponent component.
 */
export class ConditionsViewComponent extends BaseComponent implements OnInit {

	/**
	 * The current locale
	 * @type {string}
	 */
	locale: string;

	/**
	 * The getState method.
	 * @type {function}
	 */
	getState: any = getState;

	/**
	 * Has the user seen the conditions or not?
	 * @type {boolean}
	 */
	hasSeenConditions: boolean = false;

	/**
	 * @property {string} returnUrl - The url to return to when all is accepted.
	 */
	returnUrl: string = '';

	/**
	 * Constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private layoutService: LayoutService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account')).subscribe((account: Account) => {
			if (account) {
				this.hasSeenConditions = account.conditionsAccepted;
			}
		}));
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const { current } = this.getState(this.store).locale;

		// Sets current locale (limited to Dutch and English)
		this.locale = (current === 'nl' || current === 'en') ? current : 'nl';

		this.addSubscription(this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '';
		}));
	}

	/**
	 * Upon clicking the actions continue button.
	 * @param {object} $event
	 * @return {void}
	 */
	onContinueClick($event: any): void {
		$event ? $event.preventDefault() : null;
		let returnPath = '/reservation/overview';

		if (this.returnUrl.length > 0 ) {
			returnPath = decodeURIComponent(this.returnUrl);
		}

		// Sets flag for hasSeenConditions
		this.authenticationService.doPostAcceptConditions().subscribe((data) => {
			this.store.dispatch(
				this.authenticationService.setConditionsRead(true)
			);
		}, error => this.store.dispatch(this.authenticationService
			.doHandleError(error, this.store.dispatch(
				this.authenticationService.setIsLoading(false)))
		), () => {

			// When accepted, re-route to /reservation/overview
			this.store.dispatch(
				this.authenticationService.setIsLoading(false)
			);
			this.router.navigate([returnPath]);
		});
	}
}
