import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { StateInterface, Layout, Account, Saml, Registration, Feedback } from '../store/state.model';
import { LocaleService } from '../services';
import { BaseComponent } from './base/base.component';
import { _, getState } from '../tools';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'app',
	template: require('./app.component.html')
})

/**
 * Class representing the App (root) component.
 */
export class AppComponent extends BaseComponent implements OnInit {

	/**
	 *  The layout state.
	 * @type {Layout}
	 */
	layout: Layout;

	/**
	 * The account state.
	 * @type {Account}
	 */
	account: Account;

	/**
	 * The saml state.
	 * @type {Saml}
	 */
	saml: Saml;

	/**
	 * The registration state.
	 * @type {Registration}
	 */
	registration: Registration;

	/**
	 * The feedback state.
	 * @type {Feedback}
	 */
	feedback: Feedback;

	/**
 	 * Class constructor.
	 * @param {LocaleService} localeService
 	 * @param {TranslateService} translate
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private translate: TranslateService,
		private localeService: LocaleService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
			})
		);

		// Subscribes to the saml state
		this.addSubscription(store.pipe(select('saml'))
			.subscribe((saml: Saml) => {
				this.saml = _.cloneDeep(saml);
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);

		// Subscribes to the feedback state
		this.addSubscription(store.pipe(select('feedback'))
			.subscribe((feedback: Feedback) => {
				this.feedback = _.cloneDeep(feedback);
			})
		);
	}

	ngOnInit(): void {
		const { current } = getState(this.store).locale;
		const currentLocale = sessionStorage.getItem('locale') || 'nl';

		// Sets default translation locale
		this.translate.setDefaultLang(currentLocale);

		if (currentLocale !== current) {
			this.store.dispatch(
				this.localeService.setCurrent(currentLocale)
			);
		}
	}

	/**
	 * Navigate by firing a navigation handler.
	 * @return {void}
	 */
	navigate($event: any): void {
		if (typeof $event.handler === 'function') {
			$event.handler();
		}
	}
}
