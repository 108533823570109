import { Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '../base/base.component';
import { _, tassign, getState, Moment } from '../../tools';
import { StateInterface, Account, Locations, Locale, Registration } from '../../store/state.model';
import { LocaleService, LayoutService, UsersService, FilterService } from '../../services';

@Component({
	template: require('./help-view.component.html')
})

/**
 * Class representing the ConfirmationViewComponent component.
 */
export class HelpViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Registration} - The registration state.
	 */
	 registration: Registration;

	/**
	 * @property {Locale}  locale - The locale state.
	 */
	locale: Locale;

	/**
	 * Constructor.
	 * @param {LocaleService} localeService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private localeService: LocaleService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
	}

	scrollDown(): void {
		window.scrollBy({
			top: 500,
			left: 0,
			behavior: 'smooth'
		});
	}

	scrollUp(): void {
		window.scrollBy({
			top: -500,
			left: 0,
			behavior: 'smooth'
		});
	}
}
