import { Component, Input, OnInit } from '@angular/core';

import { BottomBarItem } from '../../../store/layout/layout.models';

@Component({
	selector: 'form-field',
	template: require('./form-field.component.html'),
})

/**
 * Class representing the BottomBarComponent component.
 */
export class FormFieldComponent implements OnInit {
	/**
	 * @type {string} label
	 */
	@Input() label: string = 'No label provided';
	/**
	 * group
	 */
	@Input() group = null;
	/**
	 * @type {boolean} shouldTranslate
	 */
	@Input() shouldTranslate: boolean = false;
	/**
	 * @type {boolean} isSubmitted
	 */
	@Input() isSubmitted: boolean = false;
	/**
	 * @type {string} name
	 */
	@Input() name: string = '';
	/**
	 * @type {string} type
	 */
	@Input() type: string = 'text';
	/**
	 * @type {string} dynamicName
	 */
	@Input() dynamicName: string = '';

	// @TODO: make dynamic
	allLanguages = ['NL', 'EN'];
	languages = ['NL'];
	selectedLanguage = this.languages[0];
	formControls: any[] = [];
	errors: any[] = [];

	/**
	 * @type {boolean} -
	 */
	@Input() isMultilanguage: boolean;

	ngOnInit(): void {

		if (this.shouldTranslate) {
			this.dynamicName = this.name + this.selectedLanguage;
			this.languages = this.allLanguages;

			this.allLanguages.forEach(language => {
				const inputName = this.name + language;

				this.formControls.push({ name:  inputName, formControl: this.group.controls[inputName]});
			});

		} else {
			this.dynamicName = this.name;

			this.formControls.push({ name: this.name, formControl: this.group.controls[this.name] });
		}
	}

	/**
	 * Gets the input[name]
	 * @param {string} givenLanguage
	 */
	getName(givenLanguage: string): string {
		if (this.shouldTranslate) {
			return this.name + givenLanguage;
		} else {
			return this.name;
		}
	}

	/**
	 * Switches language for this component.
	 * @param {string} language [description]
	 */
	switchLangauge(language: string): void {
		this.selectedLanguage = language;
	}

	/**
	 * Toggles errors.
	 * @return [description]
	 */
	hasError(): boolean {
		let hasError = false;
		let errors = [];

		if (this.isSubmitted) {
			this.formControls.forEach(field => {
				if (!field.formControl.valid && field.formControl.errors !== null) {
					let fieldErrors = Object.keys(field.formControl.errors).filter(error => {
						return errors.indexOf(error) === -1;
					});
					fieldErrors = fieldErrors.map(error => error.toLowerCase());

					errors = [...errors, ...fieldErrors];

					hasError = true;
				}
			});
		}

		this.errors = errors;
		return hasError;
	}
}
