import { StateInterface } from '../../store/state.model';
import { Recipe, RecipeEnriched } from '../../store/recipes/recipes.models';
import { ManagementRecipe, ManagementRecipeEnriched } from '../../store/management-recipes/management-recipes.models';
import { _ } from '../../tools';
import { RecipeBasic } from '../../store/recipes/recipes.models';

interface SimpliefiedStateInterface {
	categories: any;
	locations: any;
}

/**
 * Class representing the Recipes helper.
 */
export class RecipesHelper {

	/**
	 * Get an enriched recipe.
	 * @param {Recipe|ManagementRecipe} recipe
	 * @param {StateInterface} stateSnapshot
	 * @return {RecipeEnriched|ManagementRecipeEnriched}
	 */
	static getEnrichedRecipe(recipe: any | ManagementRecipe, stateSnapshot: SimpliefiedStateInterface): RecipeEnriched | ManagementRecipeEnriched {
		const { categories, locations } = _.cloneDeep(stateSnapshot);
		const { categoryId, locationId } = recipe;

		return {
			...recipe,

			// Adds category metadata
			category: categories.items.find(category => category.id === categoryId),

			// Adds location metadata
			location: locations.items.find(location => location.id === locationId)
		};
	}

	/**
	 * Get an enriched recipe.
	 * @param {Recipe|ManagementRecipe} recipe
	 * @param {StateInterface} stateSnapshot
	 * @return {RecipeEnriched|ManagementRecipeEnriched}
	 */
	static getEnrichedManagementRecipe(managementRecipe: ManagementRecipe, recipe: Recipe, stateSnapshot: StateInterface): ManagementRecipeEnriched {
		const { categories, locations } = _.cloneDeep(stateSnapshot);
		const { categoryId, locationId } = managementRecipe;

		if (!recipe) {
			managementRecipe.labels.push('unavailable');
		}

		return {
			...managementRecipe,

			locationId: recipe ? recipe.locationId : locationId,
			availability: recipe ? recipe.availability : null,

			// Adds category metadata
			category: categories.items.find(category => category.id === categoryId),

			// Adds location metadata
			location: recipe ? locations.items.find(location => location.id === recipe.locationId) : null
		};
	}
}
