import { Injectable, Inject } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { StateInterface } from '../../store/state.model';
import { HeadersHelper } from '../../helpers';

import 'rxjs/Rx';

export interface IUsageReportItem {
	ID: number;
	CabinetUser: string;
	DefectReported: boolean;
	EndDT: string;
	PutDT: string;
	RegistrationType: number;
	RemindedICT: boolean;
	SecondPickupReminderSent: boolean;
	StartDT: string;
	Status: number;
	TakeDT: string;
	Usergroups: string;
	CreateDT: string;
	IsAdhoc: boolean;
	Recipe: string;
	resetClicked?: boolean;
}

@Injectable()

/**
 * Class representing the Reports service.
 */
export class ReportsService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>,
	) {}

	/**
	 * Retrieve recipeItems data from server
	 * @param  {number} - recipeId [description]
	 * @return {Observable}
	 */
	getUsageReport(startDate: number, endDate: number): Observable<Response> {
		let url: string = `${this.apiEndpoint}report/current`;
		return this.http.post(url, {
			startDT: startDate,
			endDT: endDate,
		}, HeadersHelper.getOptions(this.store));
	}
}
