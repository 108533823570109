import { Pipe, PipeTransform } from '@angular/core';

import { DateTimeHelper } from '../../helpers';
import { Moment } from '../../tools';

@Pipe({
	name: 'time'
})

/**
 * Class representing the TimePipe pipe.
 */
export class TimePipe implements PipeTransform {

	/**
	 * Transforms time into another format.
	 * @param {number|string} time
	 * @param {string} parseTo
	 * @return {number}
	 */
	transform(timeToParse: any, parseTo: string): number | string {

		// e.g. 1490227200 -> '01:00'
		if (parseTo === 'unixTimestamp') {
			return DateTimeHelper.getTimeByUnixTimeStamp(timeToParse);
		}

		// e.g. '2017-06-19' -> '19-06-2017'
		if (parseTo === 'readableDate') {
			return Moment(timeToParse, 'YYYY-MM-DD').format('DD-MM-YYYY');
		}

		// e.g. 60 -> '01:00'
		if (parseTo === 'timestamp') {
			return DateTimeHelper.getTime(timeToParse);
		}

		// e.g. 60 -> 1
		if (parseTo === 'hours') {
			return Math.round(timeToParse / 60);
		}

		if (parseTo === 'days') {
			return timeToParse;
		}

		// e.g. '20-10-2017'
		if (parseTo === 'date') {
			return DateTimeHelper.getDate(timeToParse);
		}

		// e.g. 1490227200 -> '23-03-2017'
		if (parseTo === 'unixDate') {
			return DateTimeHelper.getDateByUnixTimeStamp(timeToParse);
		}

		// e.g. 1490227200 -> '23-03-2017 - 01:00'
		if (parseTo === 'unixDateTime') {
			return DateTimeHelper.getDateTimeByUnixTimeStamp(timeToParse);
		}
	}
}
