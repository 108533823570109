/**********************************************
 * Requires:                                  *
 * - 'moment' module                          *
 **********************************************/


import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from '../../tools';

@Pipe({
	name: 'moment'
})
export class MomentPipe implements PipeTransform {

	transform(value: (Moment.Moment | string), args?: any): string {
		let result = Moment(value || null);
		if (result.isValid()) {
			return result.utc().format(args || 'D MMMM YYYY');
		}
		return null;
	}
}
