import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store, select } from '@ngrx/store';

import { StateInterface, Locale, Account, Locations, Categories, Recipes, Layout, Filter, Registration } from '../../../store/state.model';
import { RecipeBasic } from '../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../interfaces/api-response.interface';
import { AuthenticationService, FilterService, LayoutService, RecipesService, UsersService } from '../../../services';
import { DateTimeHelper, RecipesHelper, ItemsHelper } from '../../../helpers';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState, Moment } from '../../../tools';
import { dateConstants } from '../../../app-constants';
import { map } from 'rxjs/operators';

@Component({
	selector: 'filter',
	template: require('./filter-view.component.html')
})

/**
 * Class representing the FilterViewComponent component.
 */
export class FilterViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Account} - The account state.
	 */
	account: Account;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Categories} - The categories state.
	 */
	categories: Categories;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	recipes: Recipes;

	/**
	 * @type {Array} - Simpliefied recipes list.
	 */
	simplifiedRecipies: any = [];

	/**
	 * @type {Layout} - The layout state.
	 */
	layout: Layout;

	/**
	 * @type {Filter} - The filter state.
	 */
	filter: Filter;

	/**
	 * @type {Registration} - The registration state.
	 */
	registration: Registration;

	/**
	 * @type {Categories} - Currently visible categories.
	 */
	visibleCategories: Categories;

	/**
	 * @type {boolean} - The current filter mode
	 */
	isOverview: boolean = true;

	/**
	 * @property {number} preferedLocation - The users prefered location.
	 */
	preferedLocation: number = 0;

	/**
	 * @property {number} selectedLocation - The selected location.
	 */
	selectedLocation: number = 0;

	/**
	 * @property {string} language - The users prefered language.
	 */
	language: string = 'nl';
	/**
	 * @property {boolean} isLoading
	 */
	isLoading: boolean = true;
	/**
	 * @property {string} locationAvailability - The opening times for the location.
	 */
	locationAvailability: string = '';

	/**
	 * Class constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {FilterService} filterService
	 * @param {RecipesService} recipesService
	 * @param {LayoutService} layoutService
	 * @param {UsersService} usersService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private filterService: FilterService,
		private recipesService: RecipesService,
		private layoutService: LayoutService,
		private usersService: UsersService,
		private router: Router,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
				const { current } = this.locale;
				this.language = current;
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('recipes'))
			.subscribe((recipes: Recipes) => {
				this.recipes = _.cloneDeep(recipes);

				this.simplifiedRecipies = recipes.itemsVisible;

				this.filterRecipies();
			})
		);

		// Subscribes to the filter state
		this.addSubscription(store.pipe(select('filter'))
			.subscribe((filter: Filter) => {
				this.filter = _.cloneDeep(filter);

				this.filterRecipies();
			})
		);

		// Subscribes to the account state
		this.addSubscription(store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
				this.preferedLocation = account.meta.preferedLocationId;
				if (this.selectedLocation === 0 && this.locations.items.length > 0 && this.preferedLocation) {

					const preferedLocationMatch = this.locations.items.filter(location => {
						return location.id === this.preferedLocation;
					});

					// Check if there were matches. Otherwise pick the first one.
					if (preferedLocationMatch.length > 0) {
						this.applyFilter({ locationId: this.preferedLocation });
					} else {
						this.applyFilter({ locationId: this.locations.items[0].id });
					}

				}
			})
		);

		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);

				if (this.layout && this.categories) {
					const { isCategoriesExpanded } = this.layout;
					let { items } = this.categories;

					// Leaves only 6 categories if !isCategoriesExpanded
					this.visibleCategories = items.slice(0, isCategoriesExpanded ? items.length : 6);
					console.log('this.visibleCategories');
					console.log(this.visibleCategories);
				}
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Sets filter mode
		this.isOverview = this.router.routerState.snapshot.url === '/reservation/overview';

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: null,
				rightNav: { label: 'reservations-text', handler: () => this.router.navigate(['/reservation/active']) }
			})
		);
	}

	/**
	 * Get recipes by date and location
	 * @param  dateString [description]
	 * @param  locationId [description]
	 * @return            [description]
	 */
	getRecipesByDate(dateString: string) {
		let date = dateString || Moment().format(dateConstants.displayDateFormat);
		const locationId = this.selectedLocation;
		const isToday = DateTimeHelper.getTodayAsString() === date;
		const now = Moment();
		const maxTime = Moment(this.filter.timeMax, ['HH:mm']);

		// Get results for tomorrow when its today and maxTime exceeds openingtime.
		if (isToday && now > maxTime) {
			date = Moment(date, dateConstants.displayDateFormat).add(1, 'day').format(dateConstants.displayDateFormat);
		}

		this.isLoading = true;
		this.store.dispatch(this.recipesService.setIsLoading(true));

		this.recipesService.hydrateRecipesByDateAndLocation(date, locationId).pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;

				this.store.dispatch(
					this.recipesService.processRecipes(date, result)
				);

				this.store.dispatch(
					this.recipesService.filteredRecipes(ItemsHelper.mapRecipeItems(result)),
				);

				this.store.dispatch(
					this.filterService.setLocationOpeningTimes(date, result),
				);

				this.isLoading = false;
				this.store.dispatch(this.recipesService.setIsLoading(false));

				// If the date has been changed somewhere else (not in the filter).
				if (this.filter.date !== date) {
					this.store.dispatch(
						this.filterService.editFilter({
							date,
						})
					);
				}

				if (result.length > 0) {
					const locationAvailability = result[0].LocationAvailability;

					if (this.locationAvailability !== locationAvailability) {
						this.locationAvailability = locationAvailability;
					}
				}
			});
	}

	/**
	 * Remove the onboarding message.
	 * @return {void}
	 */
	dismissOnboarding(): void {
		this.store.dispatch(
			this.authenticationService.setIsNew(false)
		);
	}

	scrollDown(): void {
		window.scrollBy({
			top: 500,
			left: 0,
			behavior: 'smooth'
		});
	}

	scrollUp(): void {
		window.scrollBy({
			top: -500,
			left: 0,
			behavior: 'smooth'
		});
	}

	/**
	 * Togle isCategoriesExpanded.
	 * @param {object} event
	 * @return {void}
	 */
	toggleIsCategoriesExpanded($event: any): void {
		const { isCategoriesExpanded } = this.layout;

		$event.preventDefault();
		this.store.dispatch(
			this.layoutService.toggleIsCategoriesExpanded(!isCategoriesExpanded)
		);
	}

	/**
	 * Apply a new filter.
	 * @param {Filter} newFilter
	 * @return {void}
	 */
	applyFilter(newFilter: Filter): void {
		const key = Object.keys(newFilter)[0];

		// Casts locationId to number (if applicable)
		if (key === 'locationId') {
			newFilter.locationId = parseInt(_.clone(newFilter.locationId)) || null;

			this.selectedLocation = newFilter.locationId;

			this.getRecipesByDate(this.filter.date);
		}

		// User changed a date, update time if needed.
		if (key === 'date') {
			if (newFilter.date === DateTimeHelper.getTodayAsString()) {
				newFilter.time = DateTimeHelper.getNearestReservationTimeForToday().format('HH:mm');
			}

			this.getRecipesByDate(newFilter.date);
		}

		// Edits the filter
		this.store.dispatch(
			this.filterService.editFilter(newFilter)
		);
	}

	filterRecipies(): void {
		// Fallback when intializing

		if (!this.filter || !this.recipes) {
			return;
		}

		const { filter, recipes } = this;
		const { itemsVisible } = recipes;
		const { query, categoryId, time, date } = filter;

		const filteredRecipies = itemsVisible.filter(recipe => {
			let queryMatchesRecipe = false;

			Object.keys(recipe.name).forEach(language => {
				if (recipe.name[language].toLowerCase().indexOf(query.toLowerCase()) > -1) {
					queryMatchesRecipe = true;
				}
			});

			return queryMatchesRecipe;
		}).filter(recipe => {
			if (categoryId !== null && categoryId !== 'all') {
				return categoryId === recipe.categoryId;
			}

			return true;
		}).filter(recipe => {
			// Just show it when availability isn't available yet. Oh the irony...
			// @NOTE: Remove this and your filter stops working.
			if (recipe.availability.length === 0) {
				return true;
			}

			let today = Moment();
			let selectedDate = date && time ? Moment(date + ' ' + time, ['DD-MM-YYYY HH:mm']) : today;
			let isToday = false;
			let minDateTime = selectedDate.clone();
			let maxDateTime = selectedDate.clone().add(24, 'hours');

			// Filter on date.
			return recipe.availability.filter(availability => {
				const availabilityStartDT = Moment(availability.date).add(availability.start, 'minutes');
				const availabilityEndDT = Moment(availability.date).add(availability.end, 'minutes');
				const isAvailable = availabilityEndDT >= minDateTime && availabilityEndDT <= maxDateTime;

				// @NOTE: Leave for debugging purposes now.
				// if (!isAvailable) {
				// console.group(recipe.name);
				// console.log('selectedDate', selectedDate.format('DD-MM-YYYY HH:mm'));
				// console.log(isAvailable, availability);
				// console.log('Available from: ' + availabilityStartDT.format('DD-MM-YYYY HH:mm'));
				// console.log('Available until: ' + availabilityEndDT.format('DD-MM-YYYY HH:mm'));
				// console.log('Range starts at: ' + minDateTime.format('DD-MM-YYYY HH:mm'));
				// console.log('Range ends at: ' + maxDateTime.format('DD-MM-YYYY HH:mm'));
				// console.groupEnd();
				// }

				return isAvailable;
			}).length > 0;
		}).map(recipe => {
			let location = null;
			let locationName = null;
			const preferedLocationMatch = this.locations.items.filter(location => {
				return location.id === recipe.locationId;
			});

			if (preferedLocationMatch[0]['name']) {
				locationName = preferedLocationMatch[0]['name'][this.language];
			}

			return Object.assign({}, recipe, { locationName: locationName });
		});

		this.simplifiedRecipies = filteredRecipies;
	}
}
