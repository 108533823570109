import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { Actions, ActionTypes } from '../../store/customnotifications/customnotifications.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { getState, tassign } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the custom notifications service.
 */
export class CustomNotificationsService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve custom notifications data from server and dispatch action
	 * to update the store accordingly.
	 * @return {Observable}
	 */
	hydrateCustomNotifications(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http.get(`${this.apiEndpoint}customnotification`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to load customnotifications.
	 * @param {object} result
	 * @return {Actions}
	 */
	loadCustomNotifications(result: any): Actions {
		const { customnotifications } = getState(this.store);

		return {
			type: ActionTypes.LOAD_CUSTOMNOTIFICATIONS,
			payload: tassign(customnotifications, {
				items: ItemsHelper.mapCustomNotificationItems(result),
				isLoading: false
			})
		};
	}

	/**
	 * Gets a single location by ID.
	 * @param {string} id
	 * @return {Observable<Response>}
	 */
	getCustomNotificationById(id: string): Observable<Response> {
		return this.http.get(`${this.apiEndpoint}customnotification/${id}`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Gets the availability for a location by id.
	 * @param {string} id
	 * @return {Observable<Response>}
	 */
	patchCustomNotificationById(id: string, payload: any): Observable<Response> {
		return this.http.patch(`${this.apiEndpoint}customnotification/${id}`, payload, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}

}