import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_AUDITLOG: '[Audit Log] LOAD_AUDITLOG',
	RESET_STATE: '[Audit Log] RESET_STATE',
	SET_IS_LOADING: '[Audit Log] SET_IS_LOADING'
};

export class LoadAuditLogAction implements Action {
	readonly type = ActionTypes.LOAD_AUDITLOG;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadAuditLogAction
	| SetIsLoadingAction;