import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../../store/state.model';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, LayoutService } from '../../../../services';

@Component({
	selector: 'user-list-item',
	template: require('./user-list-item.component.html')
})

export class UserListItemComponent implements OnInit {
	/**
	 * @type {string} - The user's first name.
	 */
	@Input() firstName: string;

	/**
	 * @type {string} - The user's last name.
	 */
	@Input() lastName: string;

	/**
	 * @type {string} - The user's username.
	 */
	@Input() userName: string;

	/**
	 * The icon to display.
	 * @type {string}
	 */
	@Input() icon: string;

	/**
	 * Constructor.
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	/**
	 * ngOnInit
	 */
	ngOnInit() {

	}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: Event): void {
		$event.preventDefault();
	}
}
