import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { FeedbackService, AuthenticationService } from '../services';
import { UseTranslationPipe } from '../pipes';
import { StateInterface } from '../store/state.model';
import { HeadersHelper, ItemsHelper } from '../helpers';
import { getState } from '../tools';

@Injectable()

/**
 * Class representing the Cabinet AuthGuard.
 */
export class CabinetAuthGuard implements CanActivate {

	/**
 	 * Class constructor.
	 * @param {FeedbackService} feedbackService
 	 * @param {Router} router
 	 * @param {Http} http
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private feedbackService: FeedbackService,
		private AuthenticationService: AuthenticationService,
		private router: Router,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	canActivate(nextUrl: ActivatedRouteSnapshot, current: RouterStateSnapshot): boolean | Promise<boolean> {
		const useTranslation = new UseTranslationPipe(this.store);
		const CabinetRes = this.http.get(`${this.apiEndpoint}cabinet`, HeadersHelper.getOptions(this.store)).toPromise();
		let allow = false;

		if (nextUrl && nextUrl.params) {
			const { id } = nextUrl.params;
			const checkCabinetID = Number(id);

			const result = CabinetRes.then((response: any): boolean => {
				const results = JSON.parse(response._body).result;

				results.forEach((cabinet) => {
					let permittedCabinetID = cabinet.ID;

					if (checkCabinetID === permittedCabinetID) {
						allow = true;
					}
				});

				if (!allow) {
					// Adds notification
					this.store.dispatch(
						this.feedbackService.addNotification({
							text: useTranslation.transform({
								en: 'No persmission',
								nl: 'Geen toegang'
							})
						})
					);

					// Redirects to /login
					this.router.navigate(['/management/cabinets']);
				}

				return allow;
			})
			.catch(error => {
				// Adds notification
				this.store.dispatch(
					this.feedbackService.addNotification({
						text: useTranslation.transform({
							en: 'No persmission',
							nl: 'Geen toegang'
						})
					})
				);

				// Redirects to /login
				this.router.navigate(['/management/cabinets']);
				return false;
			});

			return result;
		}
	}
}