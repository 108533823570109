import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { Actions, ActionTypes } from '../../store/auditlog/auditlog.actions';
import { StateInterface } from '../../store/state.model';
import { HeadersHelper, ItemsHelper } from '../../helpers';
import { _, tassign, getState } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Auditlog service.
 */
export class AuditlogService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Retrieve the audit of an given Entity
	 * @param  {string}		tablename 	the type/name of the entity
	 * @param  {string}		recordID 	the id of the entity
	 * @param  {boolean}	grouped 	get connected entities
	 * @return {Observable} [description]
	 */
	loadAuditlog(tablename: string, recordID: string, grouped: boolean): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http
			.get(`${this.apiEndpoint}auditlog/${tablename}/${recordID}/${grouped}`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}