import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './management-recipes.actions';
import { ManagementRecipesInterface as ManagementRecipes } from './management-recipes.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialManagementRecipesState: ManagementRecipes = {
	items: [],
	selected: null,
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Recipes} state - the current state
 * @return {Recipes} The new state
 */
export const managementRecipes: ActionReducer<any, Actions> = (state: ManagementRecipes = initialManagementRecipesState, action: Actions): ManagementRecipes => {
	switch (action.type) {

		case ActionTypes.LOAD_RECIPES:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialManagementRecipesState;

		case ActionTypes.SET_SELECTED:
			return tassign(state, {
				selected: action.payload
			});

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});

		case ActionTypes.DELETE_RECIPE:
			return tassign(state, {
				items: action.payload
			});

		case ActionTypes.ADD_RECIPE:
			return tassign(state, {
				items: action.payload
			});

		case ActionTypes.UPDATE_RECIPE:
			return tassign(state, {
				items: action.payload
			});
	}
	return state;
};
