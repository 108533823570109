import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_APPLICATION_SETTINGS: '[Application Settings] LOAD_APPLICATION_SETTINGS',
	RESET_STATE: '[Application Settings] RESET_STATE',
	SET_IS_LOADING: '[Application Settings] SET_IS_LOADING'
};

export class LoadApplicationSettingsAction implements Action {
	readonly type = ActionTypes.LOAD_APPLICATION_SETTINGS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadApplicationSettingsAction
	| SetIsLoadingAction;