import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { Headers, Response } from '@angular/http';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ItemsHelper, ValidationHelper } from '../../../../helpers';
import { LayoutService, AuthenticationService, CustomNotificationsService } from '../../../../services';
import { BaseComponent } from '../../../base/base.component';
import { StateInterface, CustomNotifications } from '../../../../store/state.model';
import { map } from 'rxjs/operators';

@Component({
	template: require('./customnotification-view.component.html')
})

/**
 * Class representing the CustomNotificationViewComponent component.
 */
export class CustomNotificationViewComponent extends BaseComponent {

	/**
	 * customNotification
	 */
	customNotification: any = null;
	/**
	 * The login form fields.
	 * @property {FormGroup} loginForm
	 */
	notificationsForm: FormGroup;

	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	customNotificationId: string = '';

	/**
	 * The selected customNotification name for the subtitle.
	 * @type {string}
	 */
	customNotificationSubtitle: string = '';

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;

	/**
	 * Is loading
	 * @type {boolean}
	 */
	isLoading: boolean = true;

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @param {CustomNotificationsService} CustomNotificationsService
	 * @param {AuthenticationService} authenticationService
	 * @param {FormBuilder} formBuilder
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private customNotificationsService: CustomNotificationsService,
		private authenticationService: AuthenticationService,
		private formBuilder: FormBuilder,
	) {
		super();

		// Build a form
		this.notificationsForm = this.formBuilder.group({
			translationNL: [{ value: '', disabled: false }, []],
			translationEN: [{ value: '', disabled: false }, []],
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.customNotificationId !== id) {
				this.customNotificationId = id;
				this.fetchCustomNotificationById(id);
			}
		});
	}

	/**
	 * Sets cabinet and location based on the ID in the url.
	 * @param {string} id
	 */
	fetchCustomNotificationById(id: string): void {
		this.customNotificationsService.getCustomNotificationById(id).pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { result } = data;

				if (result.length > 0) {
					this.customNotification = ItemsHelper.mapCustomNotificationItems(result)[0];
					// Set the subtitle for the overview
					this.customNotificationSubtitle = this.customNotification.name;


					// Patch ze values
					this.notificationsForm.patchValue({
						translationNL: this.customNotification.translationNL,
						translationEN: this.customNotification.translationEN,
					});

					this.isLoading = false;
				}
			});
	}

	/**
	 * Post login credentials if form is valid and submitted;
	 * @return {void}
	 */
	onSubmit(): void {
		this.isSubmitted = true;

		if (this.notificationsForm.valid) {
			this.isLoading = true;

			const payload = {
				TranslationNL: this.notificationsForm.controls['translationNL'].value,
				TranslationEN: this.notificationsForm.controls['translationEN'].value,
			};

			this.customNotificationsService.patchCustomNotificationById(this.customNotificationId, payload)
				.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
				.subscribe((location: ApiResponse) => {
					this.isLoading = false;
				});
		}
	}
}