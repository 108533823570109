import { Injectable } from '@angular/core';

import { Actions, ActionTypes } from '../../store/feedback/feedback.actions';
import { StateInterface, Feedback } from '../../store/state.model';
import { Notification } from '../../store/feedback/feedback.models';
import * as moment from 'moment';
import { UuidHelper } from '../../helpers';

@Injectable()

/**
 * Class representing the FeedbackService service.
 */
export class FeedbackService {
	public static readonly HIDE_DELAY_IN_SECONDS = 10;

	/**
	 * Add a notification
	 * @param {Notifcation} notification
	 * @return {Actions}
	 */
	addNotification(notification: Notification): Actions {
		const notificationWithExpiration = Object.assign({}, notification, {
			id: UuidHelper.generate(),
			expiresAt: moment().add(FeedbackService.HIDE_DELAY_IN_SECONDS, 's').format(),
		});

		return {
			type: ActionTypes.ADD_NOTIFICATION,
			payload: notificationWithExpiration
		};
	}

	/**
	 * Dismiss a notification
	 * @param {number} notificationId
	 * @return {Actions}
	 */
	dismissNotification(notificationId: number): Actions {
		return {
			type: ActionTypes.DISMISS_NOTIFICATION,
			payload: notificationId
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}
