import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { StateInterface, Layout } from '../../store/state.model';
import { BaseComponent } from '../base/base.component';
import { _ } from '../../tools';

@Component({
	template: require('./management-view.component.html')
})

/**
 * Class representing the ManagementViewComponent component.
 */
export class ManagementViewComponent extends BaseComponent {

	/**
	 * The layout state.
	 * @type {Layout}
	 */
	layout: Layout;

	/**
	 * Constructor.
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);
			})
		);
	}
}