import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Headers, Response } from '@angular/http';
import { Store, select } from '@ngrx/store';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, Locale, Locations, Categories, Recipes, Layout, Filter, Registration } from '../../../store/state.model';
import { AuthenticationService, LayoutService, RegistrationService } from '../../../services';
import { DateTimeHelper, RecipesHelper } from '../../../helpers';
import { BaseComponent } from '../../base/base.component';
import { _, tassign, getState } from '../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./active-view.component.html')
})

/**
 * Class representing the OrderViewComponent component.
 */
export class ActiveViewComponent extends BaseComponent implements OnInit {

	/**
	 * @type {any} - The locale state.
	 */
	registrations: Registration[];

	/**
	 * @type {Registration} - The registration state.
	 */
	 registration: Registration;

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * @type {Locations} - The locations state.
	 */
	locations: Locations;

	/**
	 * @type {Categories} - The categories state.
	 */
	categories: Categories;

	/**
	 * @type {Recipes} - The recipes state.
	 */
	recipes: Recipes;

	/**
	 * @type {Layout} - The layout state.
	 */
	layout: Layout;

	/**
	 * @type {Categories} - Currently visible categories.
	 */
	visibleCategories: Categories;

	/**
	 * Class constructor.
	 * @param {AuthenticationService} authenticationService
	 * @param {LayoutService} layoutService
	 * @param {RegistrationService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private authenticationService: AuthenticationService,
		private layoutService: LayoutService,
		private registrationService: RegistrationService,
		private router: Router,
		private store: Store<StateInterface>) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = _.cloneDeep(locations);
			})
		);

		// Subscribes to the categories state
		this.addSubscription(store.pipe(select('categories'))
			.subscribe((categories: Categories) => {
				this.categories = _.cloneDeep(categories);
			})
		);

		// Subscribes to the recipes state
		this.addSubscription(store.pipe(select('recipes'))
			.subscribe((recipes: Recipes) => {
				this.recipes = _.cloneDeep(recipes);

				this.recipes.items = this.recipes.items
					// Adds metadata (category and location)
					.map(recipe => RecipesHelper.getEnrichedRecipe(recipe, getState(store)));
			})
		);

		this.registrationService.getCancellableRegistrations().pipe(
			map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { success, result } = data;
				this.registrations = _.cloneDeep(result);

				this.registrations = this.registrations.map((item: any) => {
					return {
						...item,
						recipe: this.recipes.items.find(recipe => recipe.id === item.RecipeID)
					};
				});
			}, (error: any) => {
				console.log(error);
			});

		// Subscribes to the layout state
		this.addSubscription(store.pipe(select('layout'))
			.subscribe((layout: Layout) => {
				this.layout = _.cloneDeep(layout);

				if (this.layout && this.categories) {
					const { isCategoriesExpanded } = this.layout;
					let { items } = this.categories;

					// Leaves only 6 categories if !isCategoriesExpanded
					this.visibleCategories = items.slice(0, isCategoriesExpanded ? items.length : 6);
				}
			})
		);

		// Subscribes to the registration state
		this.addSubscription(store.pipe(select('registration'))
			.subscribe((registration: Registration) => {
				this.registration = _.cloneDeep(registration);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: { label: 'overview-text', handler: () => this.router.navigate(['/reservation/overview']) },
				rightNav: null
			})
		);
	}

	scrollDown(): void {
		window.scrollBy({
			top: 500,
			left: 0,
			behavior: 'smooth'
		});
	}

	scrollUp(): void {
		window.scrollBy({
			top: -500,
			left: 0,
			behavior: 'smooth'
		});
	}

	public removeItemFromList(registrationId: number) {
		this.registrations = this.registrations.filter((registration: any) => registration.ID !== registrationId);
	}
}
