import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../auth/authentication.service';
import { StateInterface, Account, Registration } from '../../store/state.model';
import { UserMeta } from '../../store/auth/auth.models';
import { HeadersHelper, DateTimeHelper } from '../../helpers';
import { _, getState, tassign, Moment } from '../../tools';
import { Actions, ActionTypes } from '../../store/auth/auth.actions';

import 'rxjs/Rx';
import { map } from 'rxjs/operators';

export interface User {
	ID: number;
	UserID: string;
	FirstName: string;
	LastName: string;
	Username: string;
	Language: string;
	CardCode: string;
	CreateDT: string;
	LastLogonDT: string;
	LastSyncDT: string;
	IsDirty: boolean;
	IsDeleted: boolean;
	IsBlacklisted: boolean;
	DeleteDT: string;
	UpdateDT: string;
	ConditionsAccepted: boolean;
	PreferedLocationId: number;
}

@Injectable()

/**
 * Class representing the Registration service.
 */
export class UsersService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {AuthenticationService} authenticationService
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private authenticationService: AuthenticationService,
		private http: Http,
		private store: Store<StateInterface>
	) {}

	/**
	 * Performs a call to get all users.
	 * @return {Observable}
	 */
	getUsers(): Observable<any> {
		// Posts data
		return this.http
			.get(`${this.apiEndpoint}cabinetUser`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to update a user.
	 * @return {Observable}
	 */
	createUser(data: any): Observable<any> {
		// Posts data
		return this.http
			.post(`${this.apiEndpoint}cabinetUser`, data, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to update a user.
	 * @return {Observable}
	 */
	updateUser(userId: number, data: any): Observable<any> {
		// Posts data
		return this.http
			.patch(`${this.apiEndpoint}cabinetUser/` + userId, data, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Performs a call to delete a user.
	 * @return {Observable}
	 */
	deleteUser(userId: number): Observable<any> {
		// Posts data
		return this.http
			.delete(`${this.apiEndpoint}cabinetUser/` + userId, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Gets the user's preferences.
	 * @return {Observable<Response>}
	 */
	getUserMetaData(): Observable<Response> {
		return this.http.get(`${this.apiEndpoint}cabinetUserMeta`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Changes the user's preferences.
	 * @param  {UserMeta} data - an user meta object.
	 * @return {Observable}
	 */
	patchUserMeta(userMeta: UserMeta): Observable<any> {
		return this.http
			// .get(`${this.apiEndpoint}cabinetUserMeta`, HeadersHelper.getOptions(this.store));
			.patch(`${this.apiEndpoint}cabinetUserMeta`, userMeta, HeadersHelper.getOptions(this.store)).pipe(map(response => response.json()));
	}

	/**
	 * Updates the store with the new metaData.
	 * @param  {UserMeta} userMeta - The updated meta data.
	 * @return
	 */
	setUserMeta(userMeta: UserMeta): Actions {
		return {
			type: ActionTypes.PATCH_USER_META_DATA,
			payload: userMeta,
		};
	}
}
