import { Component, ViewChild, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';

import { Recipe, RecipesAvailabilityItem } from '../../../../store/recipes/recipes.models';
import { DateTimeHelper } from '../../../../helpers';
import { Moment } from '../../../../tools';

const Flatpickr = require('flatpickr');
const Dutch = require('flatpickr/dist/l10n/nl.js').nl;

@Component({
	selector: 'availability-slot',
	template: require('./availability-slot.component.html')
})

/**
 * Class representing the AvailabilitySlotComponent component.
 */
export class AvailabilitySlotComponent implements OnInit {

	/**
	 * @type {ElementRef} - The element on which to bind the datepicker.
	 */
	@ViewChild('dateInput', { static: false }) dateInput: ElementRef;

	/**
	 * {boolean} locale
	 */
	@Input() locale: string;

	/**
	 * {boolean} Wether the slot is open
	 */
	@Input() isSlotOpen: boolean;

	/**
	 * {RecipesAvailabilityItem} The slot.
	 */
	@Input() slot: RecipesAvailabilityItem;

	/**
	 * {Recipe} Recipe used in this reservation, used to retrieve additional slots
	 */
	@Input() recipe: Recipe;
	/**
	 * {number} The maximum lending duration (in minutes).
	 */
	@Input() maxDuration: number;

	/**
	 * {number} Is the registration loading (typically during a call)?
	 */
	@Input() isLoading: boolean =  false;

	/**
	 * {EventEmitter} The component toggle event emitter.
	 */
	@Output() toggle: EventEmitter<any> = new EventEmitter();

	/**
	 * {EventEmitter} The component submit event emitter.
	 */
	@Output() submit: EventEmitter<any> = new EventEmitter();

	/**
	 * @type {number} - Starting time in unix timestamp in seconds when the reservation begins
	 */
	startTimestamp: number;

	/**
	 * @type {number} - The desired start time (pickup) in minutes from midnight
	 */
	startTime: number;

	/**
	 * @type {string} - The desired date of the pickup in YYYY-MM-DD format
	 */
	startDate: string;

	/**
	 * @type {number} - End time of the reservation in unix timestamp in seconds
	 */
	endTimestamp: number;

	/**
	 * @type {number} - The desired end time (return) in minutes from midnight
	 */
	endTime: number;

	/**
	 * @type {number} - The desired date of the pickup in YYYY-MM-DD format
	 */
	endDate: string;

	/**
	 * @type {number} - The upper bound of what is allowed as a valid end time of an reservation in unix timestamp in seconds
	 */
	maxEndTimestamp: number;

	/**
	 * @type {number} - The maximum end time on the last possible day in minutes from midnight
	 */
	maxEndtime: number;

	/**
	 * @type {string} - The latest possible return date in YYYY-MM-DD format
	 */
	maxEndDate: string;

	/**
	 * @type {number} - The minimum value which is valid in the endDate field in minutes from midnight
	 */
	slotEndDateStart: number;

	/**
	 * @type {number} - The maximum value which is valid in the endDate field in minutes from midnight
	 */
	slotEndDateEnd: number;

	/**
	 * {boolean} Is the maximum duration for this slot exceeded?
	 */
	isExceeded: boolean = false;

	/**
	 * {boolean} Is this an ad-hoc reservation?
	 */
	isAdHoc: boolean = !!sessionStorage.getItem('cabinetID');

	/**
	 * @type {string} Format used by the dates in the system
	 */
	dateFormat: string = 'YYYY-MM-DD';

	/**
	 * {boolean} Is the maximum duration for this slot exceeded?
	 */
	selectedDate: string;

	/**
	 * @type {object} - The datePicker element.
	 */
	datePicker: any;

	nextRegistrationStartDT: any;

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		const { date, start, end, nextRegistrationStartDT } = this.slot;

		this.nextRegistrationStartDT = nextRegistrationStartDT;
		this.startTime = start;
		this.startDate = date;

		this.selectedDate = date;

		this.getTimeslotInformation();
		this.calculateEndTime(date, start, end);

		this.maxDuration = (this.maxEndTimestamp - this.startTimestamp) / 60;

		if (this.isSlotOpen) {
			this.setDatePicker(true);
		}
	}

	/**
	 * Recalculates the max end and initial end time and date
	 *
	 * @param {string} date Startdate in YYYY-MM-DD format
	 * @param {number} start Start of the given timeslot in minutes from midnight
	 * @param {number} end End of the given timeslot in minutes from midnight
	 */
	private calculateEndTime(date: string, start: number, end: number) {
		// TODO: This could be done more elegantly but this would involve a major refactoring.
		let tempEndTime = this.endTime;

		// TODO: when reserving after closing times the first date will be invalid e.g. 23:00 while the closing time is 22:00
		this.startTimestamp = this.getTimestampFromDateTime(date, start);

		// TODO: the next registration time is sometimes at the start of a new day, should that be a valid return time?
		// The max end timestamp is the absolute end of the reservation, crossing day boundaries.
		this.maxEndTimestamp = this.getMaxEndTimestamp(this.startTimestamp, this.maxDuration, this.nextRegistrationStartDT);
		this.maxEndDate = Moment.unix(this.maxEndTimestamp).format(this.dateFormat);

		// The maxEndtime for a given time segment is indicated by the following rules:
		// The end time has to be constrained by the following:
		// 		The closing hours of the university for the given day
		// 		The maximum allowed duration of said registration
		// 		The time until the next reservation

		// Retrieve the closing time
		let slotEnd = this.getTimestampFromDateTime(this.selectedDate, this.slotEndDateEnd);
		this.maxEndtime = this.minutesSinceMidnight(this.limit(this.maxEndTimestamp, slotEnd));
		this.endTimestamp = this.getEndTimestamp(this.startTimestamp, this.maxEndTimestamp, this.slotEndDateEnd);
		this.endTime = this.minutesSinceMidnight(this.endTimestamp);
		this.endDate = Moment.unix(this.endTimestamp).format(this.dateFormat);

		// In case the already set endTime is still valid, keep it, otherwise it will be constantly jumping around
		if (tempEndTime > start && tempEndTime <= this.maxEndtime) {
			this.endTime = tempEndTime;
		} else {
			this.endTime = this.maxEndtime;
		}
	}

	/**
	 * Emit toggle change event to the parent component.
	 * @return {void}
	 */
	onToggleClick(): void {
		this.toggle.emit();

		setTimeout(() => {
			this.setDatePicker(this.isSlotOpen);
		});
	}

	/**
	 * Returns the most applicable duration unit, keeping track whether it's singular or plural.
	 *
	 * @returns {string} String starting with unit- and the unit itself e.g. day, hour, days and hours
	 */
	getDurationUnit(): string {
		let maxDurationInDays = Math.round((this.maxDuration / 60) / 24);
		if (maxDurationInDays === 0) {
			if (this.maxDuration > 60) {
				return 'unit-hours';
			} else {
				return 'unit-hour';
			}
		} else {
			if (maxDurationInDays > 1) {
				return 'unit-days';
			} else {
				return 'unit-day';
			}
		}
	}

	/**
	 * Formats the duration in a way that makes it presentable to the user.
	 *
	 * @returns {number} The duration in amount of units dictated by the getDurationUnit function
	 */
	getPresentableDuration(): number {
		let durationUnit = this.getDurationUnit();
		if (durationUnit === 'unit-day' || durationUnit === 'unit-days') {
			return Math.round(this.maxDuration / 60 / 24);
		} else {
			return Math.round(this.maxDuration / 60);
		}
	}

	/**
	 * Retrieves the required data for the given currently selected slot
	 */
	private getTimeslotInformation(): void {
		this.recipe.availability.forEach(function (recipe) {
			if (recipe.date === this.selectedDate) {
				this.slotEndDateStart = recipe.start;
				this.slotEndDateEnd = recipe.end;
			}
		}, this);
	}

	/**
	 * Returns the start timestamp which is a composition of the startdate and the starttime.
	 *
	 * @param {string} startDate Date which signifies the start of the timeslot in YYYY-MM-DD format
	 * @param {number} startTime Time in minutes relative to the start of the day
	 * @returns {number} unix timestamp in seconds
	 */
	private getTimestampFromDateTime(startDate: string, startTime: number): number {
		return Moment(startDate, this.dateFormat).add(startTime, 'minutes').unix();
	}

	/**
	 * Calculates the end timestamp (meaning the end selected by default) based on the the limit for that given slot and
	 * the maximum allowed duration.
	 *
	 * @param {number} startTimestamp The start of the reservation in unix timestamp in seconds
	 * @param {number} maxEndTimestamp The end of the reservation in unix timestamp in seconds
	 * @param {number} end The end of the slot in minutes from midnight
	 * @returns {number}
	 */
	private getEndTimestamp(startTimestamp: number, maxEndTimestamp: number, end: number): number {
		return this.limit(Moment.unix(startTimestamp).startOf('day').minutes(end).unix(), maxEndTimestamp);
	}

	/**
	 * Calculate the maximum allowed endtime, based on the maximum allowed duration and the next registration
	 * @param {number} startTimestamp tartTimestamp The start of the reservation in unix timestamp in seconds
	 * @param {number} maxDuration The maximum length of a reservation in minutes
	 * @param {number} nextRegistration The next reservation in unix timestamp in seconds
	 * @returns {number}
	 */
	private getMaxEndTimestamp(startTimestamp: number, maxDuration: number, nextRegistration: number): number {
		return this.limit(startTimestamp + maxDuration * 60, nextRegistration);
	}

	/**
	 * Calculates the minutes from midnight based on a timestamp
	 *
	 * @param {number} `timestamp unix timestamp in seconds
	 * @returns {number} Minutes from midnight
	 */
	private minutesSinceMidnight(timestamp: number): number {
		let moment = Moment.unix(timestamp);
		let midnight = moment.clone().startOf('day');
		return moment.diff(midnight, 'minutes');
	}

	/**
	 * Calculates the minumum amount of time between start and end.
	 * @returns {number}
	 */
	private getLatestEndTime(): number {
		return this.startTime + 15;
	}

	/**
	 * Caps input to the value of the limit
	 * @param {number} input value which should not be beyond the limit
	 * @param {number} limit the upper limit to which input should abide
	 * @returns {number}
	 */
	private limit(input: number, limit: number): number {
		if (input > limit) {
			return limit;
		}
		return input;
	}

	/**
	 * Initialize the datepicker.
	 * @private
	 * @param {boolean} enable
	 * @return {void}
	 */
	private setDatePicker(enable: boolean): void {
		if (this.getDurationUnit().indexOf('unit-day') !== 0 || !enable) {
			!enable ? this.datePicker.destroy() : null;
			return;
		}

		const flatPickrConfig = {
			dateFormat: 'Y-m-d',
			altFormat: 'd-m-Y',
			altInput: true,
			defaultDate: this.slot.date,
			minDate: this.slot.date,
			maxDate: this.maxEndDate,
			locale: {
				firstDayOfWeek: 1 // start week on Monday
			},
			disable: [
				function(date) {
					// TODO disabled days with no slots by returning true on days which are disabled, right now only sunday is disabled
					return (date.getDay() === 0);
				}
			],

			onChange: (selectedDates, dateStr, instance) => {
				this.onSelectedValueDateChange(dateStr);
			}
			};

		setTimeout(() => {
			this.datePicker = new Flatpickr(
				this.dateInput.nativeElement,
				this.locale === 'nl'
					? { ...flatPickrConfig, locale: Dutch }
					: flatPickrConfig
			);
		});
	}

	/**
	 * When either start or end time is changed.
	 * @param {boolean} isStart - Start or end time?
	 * @param {object} $event
	 * @return {void}
	 */
	onTimeValueChange(isStart: boolean, $event: any): void {
		const newTime = DateTimeHelper.getMinutes($event);
		if (isStart) {
			this.startTime = newTime;
			this.calculateEndTime(this.startDate, this.startTime, this.slotEndDateEnd);
		} else {
			this.endTime = newTime;
		}
		// TODO: This should no longer be possible though?
		this.isExceeded = (this.endTimestamp - this.startTimestamp) / 60 > this.maxDuration;
	}

	/**
	 * When the end date is changed.
	 * @param {string} newDate
	 * @return {void}
	 */
	onSelectedValueDateChange(newDate: string): void {
		this.selectedDate = newDate;

		// Checks what the upper and lower bounds are for a given slot
		this.getTimeslotInformation();
		this.calculateEndTime(this.startDate, this.startTime, this.slotEndDateEnd);
	}

	/**
	 * Emit submit change event to the parent component.
	 * @return {void}
	 */
	onSubmit(): void {
		this.submit.emit({
			start: this.startTime,
			end: this.endTime,
			endDate: this.selectedDate
		});
	}
}
