import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { Headers, Response } from '@angular/http';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ItemsHelper, ValidationHelper } from '../../../../helpers';
import { LayoutService, AuthenticationService, ApplicationSettingsService } from '../../../../services';
import { BaseComponent } from '../../../base/base.component';
import { StateInterface, ApplicationSettings, Locale } from '../../../../store/state.model';
import { _, tassign, getState, Moment } from '../../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./applicationsetting-view.component.html')
})

/**
 * Class representing the ApplicationSettingViewComponent component.
 */
export class ApplicationSettingViewComponent extends BaseComponent {

	/**
	 * applicationsetting
	 */
	applicationsetting: any = null;
	/**
	 * The login form fields.
	 * @property {FormGroup} loginForm
	 */
	applicationsettingsForm: FormGroup;

	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	applicationsettingId: string = '';

	/**
	 * The selected applicationsetting label for the subtitle.
	 * @type {string}
	 */
	applicationsettingSubtitle: string = '';

	/**
	 * The selected applicationsetting name.
	 * @type {string}
	 */
	applicationSettingFieldName: string = '';

	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;

	/**
	 * Is loading
	 * @type {boolean}
	 */
	isLoading: boolean = true;

	/**
	 * @type {Locale} - The locale state.
	 */
	locale: Locale;

	/**
	 * The current selected language.
	 * @type {string}
	 */
	language: string = 'nl';

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 * @param {ApplicationSettingsService} ApplicationSettingsService
	 * @param {AuthenticationService} authenticationService
	 * @param {LayoutService} LayoutService
	 * @param {FormBuilder} formBuilder
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>,
		private applicationsettingsService: ApplicationSettingsService,
		private authenticationService: AuthenticationService,
		private LayoutService: LayoutService,
		private formBuilder: FormBuilder,
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
				const { current } = this.locale;
				this.language = current;
			})
		);

		// Build a form
		this.applicationsettingsForm = this.formBuilder.group({
			value: [{ value: '', disabled: false }, []],
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.applicationsettingId !== id) {
				this.applicationsettingId = id;
				this.fetchApplicationSettingById(id);
			}
		});
	}

	/**
	 * Sets cabinet and location based on the ID in the url.
	 * @param {string} id
	 */
	fetchApplicationSettingById(id: string): void {
		this.applicationsettingsService.getApplicationSettingById(id).pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
			.subscribe((data: ApiResponse) => {
				const { result } = data;

				if (result.length > 0) {
					this.applicationsetting = ItemsHelper.mapApplicationSettingItems(result)[0];
					// Set the subtitle for the overview
					this.applicationSettingFieldName = this.applicationsetting.name;
					this.applicationsettingSubtitle = this.applicationsetting.label[this.language];

					// Patch ze values
					this.applicationsettingsForm.patchValue({
						value: this.applicationsetting.value,
					});

					this.isLoading = false;
				}
			});
	}

	/**
	 * Post login credentials if form is valid and submitted;
	 * @return {void}
	 */
	onSubmit(): void {
		this.isSubmitted = true;

		if (this.applicationsettingsForm.valid) {
			this.isLoading = true;

			const payload = {
				Value: this.applicationsettingsForm.controls['value'].value,
			};

			this.applicationsettingsService.patchApplicationSettingById(this.applicationsettingId, payload)
				.pipe(map((response: Response) => this.authenticationService.doStoreBearer(response)))
				.subscribe((location: ApiResponse) => {
					this.isLoading = false;
				});
		}
	}
}