import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { FeedbackService } from '../services';
import { UseTranslationPipe } from '../pipes';
import { StateInterface } from '../store/state.model';
import { getState } from '../tools';

@Injectable()

/**
 * Class representing the Conditions AuthGuard.
 */
export class ConditionsAuthGuard implements CanActivate {

	/**
 	 * Class constructor.
	 * @param {FeedbackService} feedbackService
 	 * @param {Router} router
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private feedbackService: FeedbackService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	canActivate(nextUrl: ActivatedRouteSnapshot, current: RouterStateSnapshot) {
		const { conditionsAccepted } = getState(this.store).account;
		const useTranslation = new UseTranslationPipe(this.store);
		const path = window.location.pathname;

		if (conditionsAccepted) {
			return true;
		}

		// Adds notification
		this.store.dispatch(
			this.feedbackService.addNotification({
				text: useTranslation.transform({
					en: 'You will have to accept the conditions first',
					nl: 'Je dient eerst de voorwaarden te accepteren'
				})
			})
		);

		this.router.navigate(['onboarding'], {
			queryParams: {
				returnUrl: this.getReturnUrl(),
			}
		});
		return false;
	}

	/**
	 * Gets the current page before redirecting to /onboarding to prevent users from having to navigate again.
	 * @return {string}
	 */
	private getReturnUrl(): string {
		const path = window.location.pathname.toLowerCase();

		// Exception urls
		if (
			path.indexOf('onboarding') > -1 ||
			path === '/reservation' ||
			path === '/'
		) {
			return '';
		}

		return encodeURIComponent(path);
	}
}
