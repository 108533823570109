import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../store/state.model';
import { BaseComponent } from '../../base/base.component';

@Component({
	template: require('./administrators-view.component.html')
})

/**
 * Class representing the AdministratorsViewComponent component.
 */
export class AdministratorsViewComponent extends BaseComponent {

	/**
 	 * Class constructor.
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private store: Store<StateInterface>) {
		super();
	}
}