import { Directive, Inject, Input, ElementRef, Renderer } from '@angular/core';

@Directive({
	selector: '[focus]'
})

/**
 * Class representing the Focus directive.
 */
export class Focus {

	/**
	 * Directive class constructor.
	 * @param {Renderer} renderer - Angular 2 DOM renderer
	 * @param {ElementRef} element - The current DOM element
	 * @return {void}
	 */
	constructor(
		public renderer: Renderer,
		@Inject(ElementRef) private element: ElementRef) {}

	/**
	 * Sets the focus.
	 * @param {boolean} condition - Focus on this element, or not
	 */
	@Input() set focus(condition: boolean) {
		if (condition) {
			setTimeout(() => {
				this.renderer.invokeElementMethod(
					this.element.nativeElement, 'focus', []
				);
			});
		}
	}
}
