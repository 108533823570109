export const routePermissions = {
	'/reservation': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/filter': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/overview': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/active': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/ict-box': ['superusers', 'administrators', 'employees'], // defective-item
	'/reservation/drop-box': ['superusers', 'administrators', 'employees'], // orders/new
	'/reservation/registration': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/registration/:id': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/conditions': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/confirmation': ['superusers', 'administrators', 'students', 'employees'],
	'/reservation/settings': ['superusers', 'administrators', 'students', 'employees'],

	'/management': ['superusers', 'administrators'],
	'/management/recipes': ['superusers', 'administrators'],
	'/management/recipe': ['superusers', 'administrators'],
	'/management/recipe/:id': ['superusers', 'administrators'],
	'/management/categories': ['superusers', 'administrators'],
	'/management/cabinets': ['superusers'],
	'/management/cabinet/:id': ['superusers'],
	'/management/cabinet/:id/location': ['superusers'],
	'/management/cabinet/:id/administrators': ['superusers'],
	'/management/cabinet/:id/availability': ['superusers'],
	'/management/cabinet/:id/auditlog': ['superusers'],
	'/management/cabinet/:id/emailsettings': ['superusers'],
	'/management/users': ['superusers', 'administrators'],
	'/management/user': ['superusers', 'administrators'],
	'/management/user/:id': ['superusers', 'administrators'],
	'/management/reports': ['superusers', 'administrators'],
	'/management/report/:id': ['superusers', 'administrators'],
	'/management/administrators': ['superusers', 'administrators'],
	'/management/customnotifications': ['superusers'],
	'/management/customnotification/:id': ['superusers'],
	'/management/applicationsettings': ['superusers'],
	'/management/applicationsetting/:id': ['superusers'],
};
