import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Headers, Response } from '@angular/http';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface } from '../../../store/state.model';
import { LayoutService, AuthenticationService, UsersService, User } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./users-view.component.html')
})

/**
 * Class representing the RecipesViewcomponent component.
 */
export class UsersViewComponent extends BaseComponent implements OnInit {

	/**
	 * The users
	 * @type {User[]}
	 */
	users: User[];

	/**
	 * The filtered users
	 * @type {User[]}
	 */
	filteredUsers: User[];

	/**
	 * @type {string} - The currently applied filter
	 */
	selectedUserState: string = 'all';

	/**
	 * @type {string} - The current search query
	 */
	currentFilterQuery: string;

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private usersService: UsersService,
		private store: Store<StateInterface>
	) {
		super();

		this.usersService.getUsers()
		.pipe(map((res: Response) => this.authenticationService.doStoreBearer(res)))
		.subscribe((data: ApiResponse) => {
			const { success, result } = data;
			this.users = _.cloneDeep(result);
			this.filteredUsers = this.users;
		}, (error: any) => {
			console.log('error', error);
		});
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}

	applyUserStateFilter(state: string): void {
		this.selectedUserState = state;

		this.applyFilter(this.currentFilterQuery);
		this.filteredUsers = this.filteredUsers.filter((user: any) => {

			// return all users if selected userstate is 'all'
			if (this.selectedUserState === 'all') {
				return true;
			}

			// If another userState has been selected, only return the users with this key true
			return user[this.selectedUserState];
		});
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		this.currentFilterQuery = query;

		this.filteredUsers = this.users.filter(user => {
			const { FirstName, LastName } = user;
			const hasQuery = (haystack, needle) => haystack ? haystack.search(new RegExp(needle, 'i')) !== -1 : false;

			if (query) {
				// Searches user metadata
				let inFirstName = hasQuery(FirstName, query);
				let inLastName = hasQuery(LastName, query);

				if (this.selectedUserState === 'all') {
					// Return users with (partial) name match
					return (inFirstName || inLastName) || false;
				}

				// Return users with the correct state and (partial) name match
				return ( user[this.selectedUserState] && (inFirstName || inLastName)) || false;
			}
			return true;
		});
	}
}
