import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Headers, Response } from '@angular/http';

import { StateInterface, Cabinets, Locale } from '../../../../../store/state.model';
import { LayoutService, AuthenticationService, CabinetsService, User } from '../../../../../services';
import { BaseComponent } from '../../../../base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { _, tassign, getState, Moment } from '../../../../../tools';
import { map } from 'rxjs/operators';

@Component({
	template: require('./administrators-view.component.html')
})

/**
 * Class representing the CabinetAdministratorsViewComponent component.
 */
export class CabinetAdministratorsViewComponent extends BaseComponent implements OnInit {
	/**
	 * @type {Locale} - The locale state.
	 */

	locale: Locale;
	/**
	 * Form submit status.
	 * @type {boolean}
	 */
	isSubmitted: boolean = false;

	/**
	 * Is validation in order or not?
	 * @type {boolean}
	 */
	isValidated: boolean = false;
	/**
	 * The selected cabinet ID.
	 * @type {string}
	 */
	cabinetId: string = '';
	/**
	 * Is loading or not?
	 * @type {boolean}
	 */
	isLoading: boolean = true;
	/**
	 * The connected cabinetusers response.
	 * @type {any}
	 */
	connectedCabinetUsers: any = null;
	/**
	 * The users
	 * @type {User[]}
	 */
	availableCabinetUsers: User[];

	/**
	 * The filtered users
	 * @type {User[]}
	 */
	filteredCabinetUsers: User[];

	/**
	 * The current selected language.
	 * @type {string}
	 */
	language: string = 'nl';

	/**
	 * The cabinets state.
	 * @type {Cabinets}
	 */
	cabinets: Cabinets;

	/**
	 * The selected cabinet name for the subtitle.
	 * @type {string}
	 */
	cabinetSubtitle: string = '';

	/**
	 * Constructor.
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {CabinetsService} CabinetsService
	 * @param {LayoutService} LayoutService
	 * @param {AuthenticationService} AuthenticationService
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private CabinetsService: CabinetsService,
		private LayoutService: LayoutService,
		private AuthenticationService: AuthenticationService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
				const { current } = this.locale;
				this.language = current;
			})
		);

		// Subscribes to the cabinets state
		this.addSubscription(store.pipe(select('cabinets'))
			.subscribe((cabinets: Cabinets) => {
				this.cabinets = _.cloneDeep(cabinets);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		this.route.params.subscribe(params => {
			const { id } = params;

			if (id && this.cabinetId !== id) {
				this.cabinetId = id;
				this.getConnectedCabinetUsers(this.cabinetId);
				this.getAvailableCabinetUsers(this.cabinetId);
				this.getCabinetNameById(id);
			}
		});

		// Resets page heaer navigation
		this.store.dispatch(
			this.LayoutService.resetState()
		);
	}

	/**
	 * Loads the auditlog based on the ID in the url.
	 * @param {string} CabinetID
	 */
	getConnectedCabinetUsers(CabinetID: string): void {
		this.CabinetsService.getConnectedCabinetUsers(CabinetID).pipe(map((res: any) => this.AuthenticationService.doStoreBearer(res)))
			.subscribe((data: any) => {
				const { result } = data;
				this.isLoading = false;
				this.connectedCabinetUsers = result;
			});
	}

	/**
	 * Retrieves all the available administrators
	 * @param {string} CabinetID
	 */
	getAvailableCabinetUsers(CabinetID: string): void {
		this.CabinetsService.getAvailableCabinetUsers(CabinetID).pipe(map((res: any) => this.AuthenticationService.doStoreBearer(res)))
			.subscribe((data: any) => {
				const { result } = data;
				this.isLoading = false;
				this.availableCabinetUsers = result;
			});
	}

	/**
	 * Adds a new cabinetuser as administrator
	 * @param {string} CabinetUserID
	 */
	addCabinetUser(CabinetUserID: string): void {
		this.CabinetsService.postConnectedCabinetUser(this.cabinetId, CabinetUserID)
			.pipe(map((response: any) => this.AuthenticationService.doStoreBearer(response)))
			.subscribe((data: any) => {
				this.isLoading = false;
				this.getConnectedCabinetUsers(this.cabinetId);
				this.getAvailableCabinetUsers(this.cabinetId);
			});
	}

	/**
	 * Adds a new cabinetuser as administrator
	 * @param {string} CabinetUserID
	 */
	removeCabinetUser(CabinetUserID: string): void {
		this.CabinetsService.deleteConnectedCabinetUser(this.cabinetId, CabinetUserID)
			.pipe(map((response: any) => this.AuthenticationService.doStoreBearer(response)))
			.subscribe((data: any) => {
				this.isLoading = false;
				this.getConnectedCabinetUsers(this.cabinetId);
				this.getAvailableCabinetUsers(this.cabinetId);
			});
	}

	/**
	 * Apply a search query filter.
	 * @param {string} query
	 */
	applyFilter(query: string): void {
		const hasQuery = (haystack, needle) => haystack ? haystack.search(new RegExp(needle, 'i')) !== -1 : false;
		const exactMatch = (left, right) => left && right ? left.toString().toLowerCase().trim() === right.toString().toLowerCase().trim() : false;

		let exactMatches = 0;

		this.filteredCabinetUsers = this.availableCabinetUsers;

		if (query) {
			this.filteredCabinetUsers = this.filteredCabinetUsers.filter(user => {
				const { FirstName, LastName, Username } = user;

				// Searches user metadata
				let inFirstName = hasQuery(FirstName, query);
				let inLastName = hasQuery(LastName, query);
				let inUsername = hasQuery(Username, query);

				let exactFirstName = exactMatch(FirstName, query);
				let exactLastName = exactMatch(LastName, query);
				let exactUsername = exactMatch(Username, query);

				if (exactFirstName || exactLastName || exactUsername) { exactMatches++; }

				return (inFirstName || inLastName || inUsername) || false;
			})
				.sort((aUser, bUser) => {
					const { FirstName: aFirstName, LastName: aLastName, Username: aUsername } = aUser;
					const { FirstName: bFirstName, LastName: bLastName, Username: bUsername } = bUser;

					let aExactFirstName = exactMatch(aFirstName, query);
					let aExactLastName = exactMatch(aLastName, query);
					let aExactUsername = exactMatch(aUsername, query);

					let bExactFirstName = exactMatch(bFirstName, query);
					let bExactLastName = exactMatch(bLastName, query);
					let bExactUsername = exactMatch(bUsername, query);

					const aHasExactMatch = (aExactFirstName || aExactLastName || aExactUsername) || false;
					const bHasExactMatch = (bExactFirstName || bExactLastName || bExactUsername) || false;

					if (aHasExactMatch && !bHasExactMatch) {
						return -1;
					} else if (!aHasExactMatch && bHasExactMatch) {
						return 1;
					} else {
						return 0;
					}
				})
				.slice(0, Math.max(exactMatches, 10));
		}
	}

	/**
	 * Sets cabinetSubtitle based on the ID.
	 * @param {string} id
	 */
	getCabinetNameById(id: string): void {
		const { items } = this.cabinets;

		let selectedCabinet = items.filter(cabinet => {
			if (parseInt(id) === cabinet.id) {
				return true;
			}
		});

		this.cabinetSubtitle = selectedCabinet[0].description;
	}
}
