import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './auth.actions';
import { AuthInterface as Auth } from './auth.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialAuthState: Auth = {
	bearer: null,
	formData: {
		username: '',
		password: ''
	},
	id: null,
	profile: {
		firstName: '',
		lastName: '',
		email: '',
	},
	userGroups: [],
	isNew: true,
	isLoggedIn: false,
	isLoading: false,
	isBlacklisted: false,
	conditionsAccepted: false,
	meta: {
		preferedLocationId: 0,
		preferedLanguage: 'nl',
	}
};

/**
 * Reduce the store.
 * @param {Auth} state - the current state
 * @return {Auth} The new state
 */
export const account: ActionReducer<any, Actions> = (state: Auth = initialAuthState, action: Actions): Auth => {
	switch (action.type) {

		case ActionTypes.STORE_BEARER:
			return tassign(state, {
				bearer: action.payload
			});

		case ActionTypes.LOAD_ACCOUNT:
			return action.payload;

		case ActionTypes.SET_IS_NEW:
			return tassign(state, {
				isNew: action.payload,
				isLoading: false
			});

		case ActionTypes.SET_IS_LOGGED_IN:
			return tassign(state, {
				isLoggedIn: action.payload
			});

		case ActionTypes.EDIT_FORMDATA:
			return tassign(state, {
				formData: tassign(state.formData, action.payload)
			});

		case ActionTypes.RESET_STATE:
			return initialAuthState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});

		case ActionTypes.SET_CONDITIONS_READ:
			return tassign(state, {
				conditionsAccepted: action.payload
			});

		case ActionTypes.PATCH_USER_META_DATA:
			const metaData = tassign(state.meta, action.payload);

			return tassign(state, {
				meta: metaData,
			});
	}
	return state;
};
