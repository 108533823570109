import { Action } from '@ngrx/store';

export const ActionTypes = {
	EDIT_FILTER: '[Filter] EDIT_FILTER',
	RESET_STATE: '[Filter] RESET_STATE',
	SET_IS_LOADING: '[Filter] SET_IS_LOADING',
	SET_DEFAULT_LOCATION: '[Filter] SET_DEFAULT_LOCATION',
	SET_OPENING_HOURS: '[Filter] SET_OPENING_HOURS',
};

export class EditFilterAction implements Action {
	readonly type = ActionTypes.EDIT_FILTER;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class SetDefaultLocation implements Action {
	readonly type = ActionTypes.SET_DEFAULT_LOCATION;
	constructor(public payload?: any) {}
}

export class SetOpeningHours implements Action {
	readonly type = ActionTypes.SET_OPENING_HOURS;
	constructor(public payload?: any) {}
}

export type Actions =
	| EditFilterAction
	| SetIsLoadingAction
	| SetDefaultLocation
	| SetOpeningHours;