import { Component, Input, OnInit } from '@angular/core';
import { Moment } from '../../../../tools';

@Component({
	selector: 'location-availability',
	template: require('./location-availability.component.html'),
})

/**
 * Class representing the BottomBarComponent component.
 */
export class LocationAvailabilityComponent implements OnInit {
	@Input() availability: any = null;
	/**
	 * @type {boolean} shouldTranslate
	 */
	@Input() shouldTranslate: boolean = false;
	/**
	 * @type {boolean} isSubmitted
	 */
	@Input() isSubmitted: boolean = false;
	/**
	 * group
	 */
	@Input() group = null;

	// @TODO: Add comments and make dynamic.
	allLanguages = ['NL', 'EN'];
	languages = ['NL'];
	selectedLanguage = this.languages[0];
	formControls: any[] = [];
	errors: any[] = [];
	prettyWeekDay: string = '';
	/**
	 * @type {boolean} -
	 */
	@Input() isMultilanguage: boolean;
	range = [];
	selectedIndex = 0;
	prefix = '';

	ngOnInit(): void {
		this.prettyWeekDay = Moment().isoWeekday(this.availability.dayOfTheWeek).format('dddd');
		this.prefix = this.availability.id + '_';

		// Defaults to CUSTOM
		let selectedIndex = 2;

		// Check values
		// CLOSED: 00:00;00 - 00:00:00
		// 24 HOURS: 00:00:00 - 23:59:59
		if (this.availability.opensAt === '00:00') {
			if (this.availability.closesAt === '00:00') {
				// closed
				selectedIndex = 0;
			} else if (this.availability.closesAt === '23:59') {
				// 24 hours
				selectedIndex = 1;
			}
		}

		this.selectedIndex = selectedIndex;

		if (this.shouldTranslate) {
			// this.dynamicName = this.name + this.selectedLanguage;
			this.languages = this.allLanguages;

			this.allLanguages.forEach(language => {

				// this.formControls.push({ name:  inputName, formControl: this.group.controls[inputName]});
			});

		} else {
			// this.dynamicName = this.name;

			// this.formControls.push({ name: this.name, formControl: this.group.controls[this.name] });
		}

		this.createTimeRange();
	}

	/**
	 * Creates a time range.
	 */
	createTimeRange(): any {
		const hours = 24;
		const minutesPerHour = 60;
		const hourDivider = this.availability.minimumPeriodInMinutes;
		const maxMinutes = minutesPerHour / this.availability.minimumPeriodInMinutes;
		let range = [];

		for (let dayAsNumber = 0; dayAsNumber < hours; dayAsNumber++) {
			let dayAsString: string = dayAsNumber.toString();
			let day: string = dayAsString.length === 1 ? `0${dayAsString}:` : `${dayAsString}:`;

			for (let minutes = 0; minutes < maxMinutes; minutes++) {
				if (minutes === 0) {
					range.push(day + '00');
				} else {
					range.push(day + minutes);
				}
			}
		}

		this.range = range;
	}

	/**
	 * Gets the input[name]
	 * @param {string} givenLanguage
	 */
	getName(type: string): string {
		const inputName =  this.availability.id + '_' + type;

		if (this.formControls.filter(formControl => formControl.name === inputName).length === 0) {
			this.formControls.push({ name: inputName, formControl: this.group.controls[inputName]});
		}

		return inputName;
	}

	/**
	 * Switches language for this component.
	 * @param {string} language [description]
	 */
	switchLangauge(language: string): void {
		this.selectedLanguage = language;
	}

	/**
	 * switches the index
	 * @param  index [description]
	 * @return       [description]
	 */
	switchIndex(index) {
		if (index === this.selectedIndex) {
			return;
		}

		if (index === 0) {
			// CLOSE THIS DAY
			this.group.patchValue({
				[this.prefix + 'opensAt']:  '00:00',
				[this.prefix + 'closesAt']:  '00:00',
			});

		} else if (index === 1) {
			// OPEN 24 HOURS
			this.group.patchValue({
				[this.prefix + 'opensAt']:  '00:00',
				[this.prefix + 'closesAt']:  '23:59',
			});

		} else {
			// CUSTOM
			this.group.patchValue({
				[this.prefix + 'opensAt']:  '07:00',
				[this.prefix + 'closesAt']:  '22:00',
			});
		}

		this.group.markAsDirty();
		this.group.markAsTouched();

		this.selectedIndex = index;
	}

	/**
	 * Toggles errors.
	 * @return [description]
	 */
	hasError(): boolean {
		let hasError = false;
		let errors = [];

		if (this.isSubmitted) {
			this.formControls.forEach(field => {
				if (!field.formControl.valid && field.formControl.errors !== null) {
					let fieldErrors = Object.keys(field.formControl.errors).filter(error => {
						return errors.indexOf(error) === -1;
					});
					fieldErrors = fieldErrors.map(error => error.toLowerCase());

					errors = [...errors, ...fieldErrors];

					hasError = true;
				}
			});
		}

		this.errors = errors;
		return hasError;
	}

	/**
	 * [valueChanged description]
	 * @param  event [description]
	 * @return       [description]
	 */
	valueChanged(event, test) {
		// console.log('valueChanged');
		// console.log(event.target.name);
		// console.log(event.target.value);
		//
		// console.log(this.group.controls[event.target.name]);
		// console.log(this.formControls);

		// Do validation magic here?

		// this.formControls.forEach(control => {
		// 	console.log(control);
		// });
	}
}
