import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_CATEGORIES: '[Categories] LOAD_CATEGORIES',
	RESET_STATE: '[Categories] RESET_STATE',
	SET_IS_LOADING: '[Categories] SET_IS_LOADING',
	UPDATE_CATEGORIES: '[Categories] UPDATE_CATEGORIES',
	ADD_CATEGORY: '[Categories] ADD_CATEGORY',
	DELETE_CATEGORY: '[Categories] DELETE_CATEGORY'
};

export class LoadCategoriesAction implements Action {
	readonly type = ActionTypes.LOAD_CATEGORIES;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export class UpdateCategoriesAction implements Action {
	readonly type = ActionTypes.UPDATE_CATEGORIES;
	constructor(public payload?: any) {}
}

export class AddCategoryAction implements Action {
	readonly type = ActionTypes.ADD_CATEGORY;
	constructor(public payload?: any) {}
}

export class DeleteCategoryAction implements Action {
	readonly type = ActionTypes.DELETE_CATEGORY;
	constructor(public payload?: any) {}
}
export type Actions =
	| LoadCategoriesAction
	| SetIsLoadingAction
	| UpdateCategoriesAction
	| AddCategoryAction
	| DeleteCategoryAction;