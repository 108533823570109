import { Action } from '@ngrx/store';

export const ActionTypes = {
	LOAD_CABINET_POSITIONS: '[Cabinet Positions] LOAD_CABINET_POSITIONS',
	RESET_STATE: '[Cabinet Positions] RESET_STATE',
	SET_IS_LOADING: '[Cabinet Positions] SET_IS_LOADING'
};

export class LoadCabinetPositionsAction implements Action {
	readonly type = ActionTypes.LOAD_CABINET_POSITIONS;
	constructor(public payload?: any) {}
}

export class SetIsLoadingAction implements Action {
	readonly type = ActionTypes.SET_IS_LOADING;
	constructor(public payload?: any) {}
}

export type Actions =
	| LoadCabinetPositionsAction
	| SetIsLoadingAction;