import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { StateInterface } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { RegistrationService, RecipesService, LayoutService } from '../../../../services';
import { Moment } from '../../../../tools';

@Component({
	selector: 'product-list-removable-item',
	template: require('./product-list-removable-item.component.html')
})

export class ProductListRemovableItemComponent {

	/**
	 * @type {string} - The account state.
	 */
	@Input('recipe') recipe: RecipeEnriched;

	/**
	 * @type {string} - The account state.
	 */
	@Input() registration: any;

	/**
	 * @type {boolean}
	 */
	@Input() isNotSelected: boolean;

	/**
	 * @type {string} - Destination link
	 */
	@Input() destinationLink: string = '/reservation/registration';

	/**
	 * @type {boolean}
	 */
	@Input() canRemove: boolean = false;

	/**
	 * @type {EventEmitter<any>} - onRemoved event
	 */
	@Output() onRemoved: EventEmitter<any> = new EventEmitter<any>();

	public startDate: string;
	public endDate: string;
	public hasImageLoaded: boolean = false;

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		if (this.registration) {
			this.startDate = Moment(this.registration.StartDT, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD MMM HH:mm');
			this.endDate = Moment(this.registration.EndDT, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD MMM HH:mm');
		}
	}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: any): void {
		const { destinationLink, recipe } = this;

		$event.preventDefault();

		this.translate.get('RESERVATION.RESERVATION.remove-confirmation-text').subscribe((translation: string) => {
			let userSelectedOption: boolean = confirm(translation);
			if (userSelectedOption) {
				this.registrationService.cancelRegistration(this.registration.ID).subscribe((data: any) => {
					this.onRemoved.emit();
					// Fetch active reservations again.
					this.registrationService.getActiveRegistrations()
						.subscribe((data) => {
							this.store.dispatch(
								this.registrationService.setActiveReservations(data.result),
							);
						}, (error: any) => {
							console.log('error', error);
						});
				}, (error: any) => {
					console.log('error', error);
				});
			}
		});
	}
}
