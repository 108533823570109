import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'onboarding',
	template: require('./onboarding.component.html')
})

/**
 * Class representing the OnboardingComponent component.
 */
export class OnboardingComponent {

	/**
	 * {string} The user's name.
	 */
	@Input() name: string;

	/**
	 * {EventEmitter} The component event emitter.
	 */
	@Output() dismiss: EventEmitter<any> = new EventEmitter();

	/**
	 * Emit change event to the parent component.
	 * @return {void}
	 */
	onClick(): void {
		this.dismiss.emit();
	}
}
