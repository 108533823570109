import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { Store } from '@ngrx/store';

import { StateInterface } from '../../../../store/state.model';
import { RecipeEnriched } from '../../../../store/recipes/recipes.models';
import { ApiResponse } from '../../../../interfaces/api-response.interface';
import { ManagementRecipeEnriched } from '../../../../store/management-recipes/management-recipes.models';
import { AuthenticationService, RegistrationService, RecipesService, ManagementRecipesService, LayoutService } from '../../../../services';
import { Moment } from '../../../../tools';

@Component({
	selector: 'product-list-item',
	template: require('./product-list-item.component.html')
})

export class ProductListItemComponent implements OnInit {

	/**
	 * @type {string} - The account state.
	 */
	@Input('recipe') recipe: RecipeEnriched | ManagementRecipeEnriched;

	/**
	 * @type {boolean}
	 */
	@Input() isNotSelected: boolean;
	/**
	 * @type {string}
	 */
	@Input() language: string;

	/**
	 * @type {string} - Destination link
	 */
	@Input() destinationLink: string = '/reservation/registration';

	/**
	 * @type {boolean} - Has the image src loaded or not?
	 */
	hasImageLoaded: boolean = false;

	availableDate: string = '';

	/**
	 * Constructor.
	 * @param {string} imagesUrl
	 * @param {RegistrationService} registrationService
	 * @param {RecipesService} recipesService
	 * @param {ManagementRecipesService} managementRecipesService
	 * @param {LayoutService} layoutService
	 * @param {Router} router
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ImagesUrl') private imagesUrl: string,
		private authenticationService: AuthenticationService,
		private registrationService: RegistrationService,
		private recipesService: RecipesService,
		private managementRecipesService: ManagementRecipesService,
		private layoutService: LayoutService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	/**
	 * ngOnInit
	 */
	ngOnInit() {
		if (this.recipe.availability.length > 0) {
			const availability = this.recipe.availability[0];
			const availabilityDate = Moment(availability.date).add(availability.start, 'minutes');

			if (this.language === 'nl') {
				this.availableDate = availabilityDate.format('DD MMMM ・ HH:mm');
			} else {
				this.availableDate = availabilityDate.format('MMMM Do ・ HH:mm');
			}
		}
	}

	/**
	 * Upon clicking on the item.
	 * @return {void}
	 */
	onClick($event: Event): void {
		const { destinationLink, recipe } = this;

		$event.preventDefault();
		setTimeout(() => this.router.navigate([destinationLink, recipe.id])); // @todo do we really need setTimeout?
	}
}
