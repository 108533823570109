import { Component, Input } from '@angular/core';

import { BottomBarItem } from '../../../store/layout/layout.models';

@Component({
	selector: 'bottom-bar',
	template: require('./bottom-bar.component.html'),
})

/**
 * Class representing the BottomBarComponent component.
 */
export class BottomBarComponent {

	/**
	 * @type {array} - Bottom bar items
	 */
	@Input() items: Array<BottomBarItem>;

	/**
	 * @type {boolean} - Is the bottom bar active or not?
	 */
	@Input() isActive: boolean;
}
