import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './locations.actions';
import { LocationsInterface as Locations } from './locations.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialLocationsState: Locations = {
	items: [],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Locations} state - the current state
 * @return {Locations} The new state
 */
export const locations: ActionReducer<any, Actions> = (state: Locations = initialLocationsState, action: Actions): Locations => {
	switch (action.type) {

		case ActionTypes.LOAD_LOCATIONS:
			return action.payload;

		case ActionTypes.RESET_STATE:
			return initialLocationsState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
