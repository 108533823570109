import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { FeedbackService } from '../services';
import { UseTranslationPipe } from '../pipes';
import { StateInterface } from '../store/state.model';
import { routePermissions } from './permissions';
import { getState } from '../tools';

@Injectable()

/**
 * Class representing the AuthGuard.
 * @see https://continuousdeveloper.com/2016/07/06/protecting-routes-in-angular-2/
 */
export class AuthGuard implements CanActivate {

	/**
 	 * Class constructor.
	 * @param {FeedbackService} feedbackService
 	 * @param {Router} router
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private feedbackService: FeedbackService,
		private router: Router,
		private store: Store<StateInterface>) {
	}

	/**
	 * Check if route can activate.
	 * @return {boolean|Observable}
	 */
	canActivate(nextUrl: ActivatedRouteSnapshot, current: RouterStateSnapshot): boolean | Observable<boolean> {
		const { bearer, isLoggedIn, userGroups } = getState(this.store).account;
		const useTranslation = new UseTranslationPipe(this.store);
		const { url } = current;
		// Removes query params from the url before checking permissions.
		const path = url.replace(/\?.*/g, '');

		if (bearer && isLoggedIn) {
			// Finds a digit with minimal of 1 digit and replaces it by /:id to match routes.
			const removeId = item => item.replace(/\/\d{1,}/gi, '/:id');

			// Only returns true if user has permission to follow this route
			const hasPermission = !!userGroups
				.find(role => !!(() => routePermissions[path] || routePermissions[Object.keys(routePermissions)
					.filter(route => route.indexOf('/:id') !== -1)
					.find(route => removeId(path) === removeId(route))]
			)().find(currentRole => currentRole === role));

			if (hasPermission) {
				return true;
			}
		}

		// Resets full state
		this.store.dispatch({ type: 'ACTION_RESET_FULL_STATE' });

		// Adds notification
		this.store.dispatch(
			this.feedbackService.addNotification({
				text: useTranslation.transform({
					en: 'No persmission',
					nl: 'Geen toegang'
				})
			})
		);

		// Redirects to /login
		this.router.navigate(['/login']);
		return false;
	}
}
