import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Headers, Response } from '@angular/http';

import { ApiResponse } from '../../../interfaces/api-response.interface';
import { StateInterface, CustomNotifications } from '../../../store/state.model';
import { CustomNotification } from '../../../store/customnotifications/customnotifications.models';
import { LayoutService, AuthenticationService } from '../../../services';
import { BaseComponent } from '../../base/base.component';
import { _, getState, tassign } from '../../../tools';

@Component({
	template: require('./customnotifications-view.component.html')
})

/**
 * Class representing the CustomNotificationsViewComponent component.
 */
export class CustomNotificationsViewComponent extends BaseComponent {
	/**
	 * The cabinets state.
	 * @type {CustomNotifications}
	 */
	customnotifications: CustomNotification;

	/**
 	 * Constructor.
	 * @param {LayoutService} layoutService
 	 * @param {Store} store
 	 * @return {void}
 	 */
	constructor(
		private layoutService: LayoutService,
		private authenticationService: AuthenticationService,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the customnotifications state
		this.addSubscription(store.pipe(select('customnotifications'))
			.subscribe((customnotifications: CustomNotifications) => {
				this.customnotifications = _.cloneDeep(customnotifications);
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Resets page heaer navigation
		this.store.dispatch(
			this.layoutService.resetState()
		);
	}
}