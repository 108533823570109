import { Component, Input } from '@angular/core';

@Component({
	selector: 'page-canvas',
	template: require('./page-canvas.component.html')
})

/**
 * Class representing the PageCanvasComponent component.
 */
export class PageCanvasComponent {

	/**
	 * The canvas title.
	 * @type {string}
	 */
	@Input() title: string;

	/**
	 * The canvas supporting text (optional).
	 * @type {string}
	 */
	@Input() supportingText: string;
}