import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './filter.actions';
import { FilterInterface as Filter } from './filter.models';
import { tassign, Moment } from '../../tools';
import { DateTimeHelper } from '../../helpers';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialFilterState: Filter = {
	query: '',
	categoryId: null,
	locationId: 0,
	date: Moment().format('DD-MM-YYYY'),
	time: DateTimeHelper.getNearestReservationTimeForToday().format('HH:mm'),
	isLoading: false,
	isTouched: false,

	// Filter 2.0
	timeMin: '07:00',
	timeMax: '22:00',
	daysClosed: [],
};

/**
 * Reduce the store.
 * @param {Filter} state - the current state
 * @return {Filter} The new state
 */
export const filter: ActionReducer<any, Actions> = (state: Filter = initialFilterState, action: Actions): Filter => {
	switch (action.type) {

		case ActionTypes.SET_DEFAULT_LOCATION:
			return tassign(state, action.payload, {
				locationSelected: true,
			});

		case ActionTypes.EDIT_FILTER:
			return tassign(state, action.payload, {
				isTouched: true,
			});

		case ActionTypes.RESET_STATE:
			return initialFilterState;

		case ActionTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.payload
			};

		case ActionTypes.SET_OPENING_HOURS:
			return tassign({}, state, action.payload);
	}
	return state;
};
