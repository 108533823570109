import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { Actions, ActionTypes } from '../../store/saml/saml.actions';
import { StateInterface, Saml } from '../../store/state.model';
import { HeadersHelper } from '../../helpers';
import { getState } from '../../tools';

import 'rxjs/Rx';

@Injectable()

/**
 * Class representing the Saml service.
 */
export class SamlService {

	/**
	 * Constructor.
	 * @param {string} apiEndPoint
	 * @param {Http} http
	 * @param {Store} store
	 * @return {void}
	 */
	constructor(
		@Inject('ApiEndpoint') private apiEndpoint: string,
		private http: Http,
		private store: Store<StateInterface>) {
	}

	/**
	 * Performs a call to retrieve a login url.
	 * @return {Observable}
	 */
	doGetSamlLogin(): Observable<Response> {
		this.store.dispatch(this.setIsLoading(true));
		return this.http.get(`${this.apiEndpoint}saml/login`, HeadersHelper.getOptions(this.store));
	}

	/**
	 * Dispatch an action to edit the state for 'loginUrl'.
	 * @param {string} loginUrl
	 * @return {Actions}
	 */
	setLoginUrl(loginUrl: string): Actions {
		return {
			type: ActionTypes.SET_LOGIN_URL,
			payload: loginUrl
		};
	}

	/**
	 * Dispatch an action to reset the entire state.
	 * @return {Actions}
	 */
	resetState(): Actions {
		return {
			type: ActionTypes.RESET_STATE
		};
	}

	/**
	 * Dispatch an action to change the state for 'isLoading'.
	 * @param {boolean} to - The new state
	 * @return {Actions}
	 */
	setIsLoading(to: boolean): Actions {
		return {
			type: ActionTypes.SET_IS_LOADING,
			payload: to
		};
	}
}