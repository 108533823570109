import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './saml.actions';
import { SamlInterface as Saml } from './saml.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialSamlState: Saml = {
	loginUrl: '',
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Saml} state - the current state
 * @return {Saml} The new state
 */
export const saml: ActionReducer<any, Actions> = (state: Saml = initialSamlState, action: Actions): Saml => {
	switch (action.type) {

		case ActionTypes.SET_LOGIN_URL:
			return tassign(state, {
				loginUrl: action.payload
			});

		case ActionTypes.RESET_STATE:
			return initialSamlState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
